import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { PaymentContext } from '../../providers/PaymentProvider';
import { useActiveAccount, useWalletBalance } from 'thirdweb/react';
import useClient from '../../hooks/useClient';
import { ZKSYNC } from '../../assets/Chains';

const Balance = () => {
  const { selectedToken: { token } } = useContext(PaymentContext);
  const activeAccount = useActiveAccount();
  const client = useClient();

  const { data, isLoading } = useWalletBalance({
    chain: ZKSYNC.id,
    address: activeAccount?.address,
    client,
    tokenAddress: '0xd9AE1b1Ec5970EaEc4b9EE37E17c7f43D6906deA',
  });

  if (token?.ticker === 'MORFI' && data) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Typography>Balance</Typography>
        <Typography sx={{ fontSize: '27px', fontWeight: '600' }}>{data.displayValue} <span style={{ fontSize: '14px' }}>MORFI</span></Typography>
      </Box>
    );
  }
};

export default Balance;