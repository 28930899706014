import { Box, Typography } from '@mui/material';
import React, { Fragment, useContext } from 'react';
import ItemCatalogo from '../Catalog/ItemCatalogo';
import { translate } from '../../translate';
import { PaymentContext } from '../../providers/PaymentProvider';
import { STATUS_PAYMENT } from '../../assets/Constants';
import { FormContext } from '../../providers/FormProvider';
import { getPath } from '../../helpers/multiuse';
import { TICKETS_TYPES, TYPES_FORMAT } from '../../Routes/Crecimiento/Constants';
import { DoubleDateFromTo } from '../DateField/DateField';
import Success from '../../Routes/Root/Success';

const OrderList = ({ showTitle, full }) => {
  const { status } = useContext(PaymentContext);
  const { form: { total, reference, ars_amount, details } } = useContext(FormContext);
  console.log(reference, status === STATUS_PAYMENT.success);
  const totalAmount = reference?.price ? reference?.price : total ? total : ars_amount;
  const path = getPath();

  if (reference) {
    return (
      <Box sx={{ width: '100%', height: '90%' }}>
        {
          showTitle && (
            <Typography sx={{ fontSize: '16px', fontWeight: '700', paddingLeft: '9px' }}>{translate.order}</Typography>
          )
        }
        {
          (status === STATUS_PAYMENT.success) && (
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>{translate.youPaid}:</Typography>
              <Typography sx={{ fontSize: '42px', fontWeight: '700' }}> ${totalAmount.toFixed(2)}</Typography>
            </Box>
          )
        }
        {
          (status === STATUS_PAYMENT.success) && (
            <Success.Twitter />
          )
        }
        {
          (path === 'crecimiento' || path === 'alephfull') ? (
            <CrecimientoOrderList full={full} />
          ) : (
            <>
              {
                reference.order?.map(item => (
                  <ItemCatalogo.Normal
                    key={item.id}
                    // discount={reference.discount}
                    item={{ ...item }}
                    selected={false}
                  />
                ))
              }
            </>
          )
        }
      </Box>
    );
  }
};

export const CrecimientoOrderList = ({ full }) => {
  const { form: { reference, details } } = useContext(FormContext);

  if (reference) {
    return (
      <Box sx={{ marginTop: '12px', padding: '0 6px', maxHeight: full ? '100%' : '50vh', overflow: 'auto' }}>
        {
          reference.tickets && (
            reference.tickets.map(item => (
              <ItemCatalogo.Normal
                key={`${item.id}-orderList`}
                discount={reference.discount}
                showPrice={item.type !== 'not'}
                item={item}
                icon={item.dates && <DoubleDateFromTo from={item.dates[0]} until={item.dates[1]} />}
                selected
              />
            ))
          )
        }
        {
          TICKETS_TYPES.map(type => {
            const hasTicket = (reference && reference[type]?.order?.length > 0) ? true : false;
            if (hasTicket) {
              return (
                <Box sx={{ padding: '3px 3px' }} key={type}>
                  <Typography sx={{ fontSize: '16px', fontWeight: '600', marginLeft: '9px' }}>{TYPES_FORMAT[type]}</Typography>
                  {
                    reference[type]?.order?.map(item =>
                      <ItemCatalogo.Normal
                        key={`${item.id}-orderList`}
                        discount={item.type !== 'not' ? reference.discount : 0}
                        showPrice={!reference.discount}
                        item={item}
                        icon={item.dates && <DoubleDateFromTo from={item.dates[0]} until={item.dates[1]} />}
                        selected
                      />
                    )
                  }
                </Box>
              );
            }
          })
        }
      </Box>
    );
  }
};

export default OrderList;