export const setItemStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getItemStorage = (key) => {
  return localStorage.getItem(key);
};

export const removeItemStorage = (key) => {
  localStorage.removeItem(key);
};