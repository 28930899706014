import React, { useContext, useEffect } from 'react';
import StepControl from '../components/StepControl/StepControl';
import { Box } from '@mui/material';
import { STATUS_PAYMENT } from '../assets/Constants';
import NotificationSnack from '../components/NotificationSnack/NotificationSnack';
import Response from './Root/Response';
import { StepContext } from '../providers/StepProvider';
import { PaymentContext } from '../providers/PaymentProvider';
import BG_IMAGE from '../assets/Images/BG_IMAGE.jpg';
// import BG from '../assets/Images/BGS.png';
import useController from '../hooks/useController';
import { getRouteLocation } from '../helpers/multiuse';
import ContainerApp from '../components/Container/ContainerApp';

const Router = () => {
  const routeConfig = getRouteLocation();

  if (routeConfig) {
    return (
      <Box sx={{ backgroundImage: `url(${BG_IMAGE})`, objectFit: 'cover', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '100%' }}>
        {
          routeConfig.isLanding ? (
            <ContainerApp style={{ backgroundColor: '#14D0EA', margin: 'auto' }}>
              <routeConfig.component />
            </ContainerApp>
          ) : (
            <CheckoutStepper routeConfig={routeConfig} />
          )
        }
        <NotificationSnack />
      </Box>
    );
  }
};

const CheckoutStepper = ({ routeConfig }) => {
  const { step } = useContext(StepContext);
  const { status } = useContext(PaymentContext);
  const isPending = status === STATUS_PAYMENT.pending;
  const isSuccess = status === STATUS_PAYMENT.success;

  const { loading, controllerParams } = useController();

  useEffect(() => {
    controllerParams();
  }, []);

  return (
    <StepControl disabled={loading}>
      {
        routeConfig.routes.map(({ step: configStep, component: Component }) => (
          (step === configStep && isPending) && (
            <Component key={configStep} />
          )
        ))
      }

      {(!isPending && isSuccess) && routeConfig?.success}

      {(!isPending && !isSuccess) && <Response />}
    </StepControl>
  );
};

export default Router;