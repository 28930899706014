import React from 'react';
import { Box, Typography, Modal, useTheme } from '@mui/material';
import ButtonRetro from '../ButtonRetro/ButtonRetro';

const ModalRetro = ({ children, open, handleClose }) => {
  const theme = useTheme();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: theme.shadows.dense,
        margin: 'auto',
        borderRadius: 3,
        overflow: 'hidden'
      }}>
        {children}
      </Box>
    </Modal>
  );
};

const Redirect = ({ tokenId }) => {

  const onPress = () => {
    if (tokenId) {
      window.open(`https://metamask.app.link/dapp/https://master.dctxbmktauhu1.amplifyapp.com/?token=${tokenId}`, '_blank').focus();
    } else {
      window.open('https://metamask.app.link/dapp/https://master.dctxbmktauhu1.amplifyapp.com', '_blank').focus();
    }
  };

  return (
    <ModalRetro open={true}>
      <Box>
        <Box sx={{ p: 3 }}>
          <Typography variant='subtitle1' textAlign={'center'} fontSize={'21px'} fontWeight={'bold'}>
            Continuar en Metamask
          </Typography>
          <Typography textAlign={'center'} fontSize={'14px'} fontWeight={'600'}>
            Asegurate de tener la wallet desbloqueada
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'center'} padding={2}>
          <ButtonRetro.Contained onPress={onPress}>Continuar</ButtonRetro.Contained>
        </Box>
      </Box>
    </ModalRetro >
  );
};

ModalRetro.Redirect = Redirect;

export default ModalRetro;