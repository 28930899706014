import { useState, createContext, useContext, useEffect } from 'react';
import { getParam } from '../assets/RoutesConfig';
import useApi from '../hooks/useApi';
import { FormContext } from './FormProvider';
import usePayment from '../hooks/usePayment';
import api, { instance } from '../api';
import useController from '../hooks/useController';
import { StepContext, StepProvider } from './StepProvider';


export const ClientContext = createContext(null);

export const ClientProvider = ({children}) => {
  const [client, setClient] = useState(null);
  const { setForm } = useContext(FormContext);
  const { setStep, setDisabled } = useContext(StepContext);
  const [alreadyPaid, setAlreadyPaid] = useState(false);
  const [loadingClient, setLoadingClient] = useState(true);

  const { getClient, checkCoupon } = useApi();
  const { getPaymentRequest, resetOrderPayment } = usePayment();

  const getClientData =  async () => {

    setLoadingClient(true);
    const clientId = getParam('application_id');

    try {
      const response = await getClient(clientId);
      if(response.data) {
        setForm(prev => ({...prev, reference: {...prev.reference, application_id: response.data.uid}}));
        
        if(typeof response.data.partner !== 'undefined') {
          
          setDisabled({prev: true, next: false});
          if(response.data.partner) {
            setStep(2);
          } else {
            setStep(1);
          }
        }
        setClient(response.data);
        setLoadingClient(false);
        return;
      }
    } catch (error) {
      
      if(typeof error.response.data.detail === 'string') {
        if(error?.response?.data?.detail?.includes('approved payment')) setAlreadyPaid(true);
      }
      setLoadingClient(false);
      return Error(error);
    }
  };

  const getClientCoupon = async () => {
    if(getParam('referral_code')){
      let referralCode = getParam('referral_code');
      try {
        const response = await checkCoupon(referralCode);
        if(response.data){
          setForm(prev => ({ ...prev, discount: response.data.Discount, reference: { ...prev.reference, referral_code: response.data.UID } }));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const checkPaymentId = async (application_id) => {
    let paymentId = localStorage.getItem('payment_id');
    if(paymentId) {
      let paymentRequest = await getPaymentRequest(paymentId);
      if(paymentRequest) {
        if(application_id === paymentRequest.reference?.application_id) return;
        return resetOrderPayment();
      }
    }
  };

  useEffect(() => {
    checkPaymentId();
    
  },[instance]);

  useEffect(() => {
    if(!window.location.href.includes('success')) {
      getClientData();
      getClientCoupon();
    }

    
    return() => {
      setClient(null);
    };
  },[]);

  return(
    <ClientContext.Provider 
      value={{
        client,
        loadingClient,
        alreadyPaid
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};