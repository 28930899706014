import axios from 'axios';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_PATH,
  timeout: 30000,
});

const catchError = (e) => {
  console.log('Error Catch : ', e.response);
  return e.response;
};


export default {
  get: async (url, params = {}) => {
    return await instance.get(url, params).then(data => data).catch(catchError);
  },

  get_one: (url, params) => {
    return instance.get(url, params).then(data => data).catch(catchError);
  },

  put: (url, data, config) => {
    return instance.put(url, data, config).then(data => data).catch(catchError);
  },

  post: (url, params) => {
    return instance.post(url, params).then(d => d);
  },

  delete: (url, params) => {
    return instance.delete(url, params).then((data) => data).catch(catchError);
  }
};