export const english = {
  payment_info_btc: 'Copy the lightning network invoice and paste it into a wallet that allows payments with lightning. Your transaction will be recognized automatically.',
  payment_info_evm: 'Send the exact amount. If you have already made the payment and we have not yet redirected you to another page, share the transaction hash with us.',
  walletButtonTitle: 'Connect Wallet',
  paymentButtonLemon: 'PAY WITH LEMON',
  amount: 'Amount',
  send_to: 'Send to',
  order: 'You are paying for',
  disconnect: 'Disconnect',
  connected: 'Connected',
  send: 'Send',
  pay: 'Pay',
  free: 'Claim',
  continue: 'Continue',
  copyAmount: 'Amount copied to clipboard.',
  copyAddress: 'Address copied to clipboard.',
  transactionExpired: 'Transaction expired. Please start a new payment.',
  newPayment: 'New Payment',
  orderNumber: 'Order',
  transactionCanceled: 'Transaction canceled. Please start a new payment.',
  paymentApproved: 'Payment approved!',
  paymentInProcess: 'We are processing the payment',
  timeEstimated: 'Estimated approval time:',
  seconds: 'seconds',
  optional: 'optional',
  insuficientAmount: 'There are not enough funds to complete the transaction.',
  youPaid: 'You Paid',
  transactionHash: 'Transaction hash',
  transactionErrorFunds: 'You don\'t have enough funds to carry out the transaction',
  hashNotFound: 'We can\'t find that transaction, please check the hash.',
  requestError: 'Error request'
};