import dayjs from 'dayjs';
import api from '../api';
import { ROUTES_CONFIG } from '../assets/RoutesConfig';

export const detectOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'PC';
};

export const getParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}; 

export const getPath = () => {
  const path = window.location.pathname?.replace(/\//g, '');
  return path;
};

export const getRouteLocation = () => {
  const path = ROUTES_CONFIG[getPath()];
  const route = path ? path : ROUTES_CONFIG['/'];
  return route;
};

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);;
};

export const fillArrayDateFromUntil = (from, until) => { //date format YYYY-MM-DD
  let response = [];
  let initDate = dayjs(from);
  let endDate = dayjs(until);

  while (initDate.isBefore(endDate) || initDate.isSame(endDate)) {
    response.push(initDate.format('YYYY-MM-DD'));
    initDate = initDate.add(1, 'day');
  }

  return response;
};

export const coupon_code = (coupon) => {
  const names = ['EMI', 'JANINE', 'PILI', 'FIO', 'SANTI', 'TULE', 'JUAN', 'MARTIN', 'JAMES', 'DIEGO'];
  const codes = ['25GO', '50ALEPH', '75YA', '90CRECI'];
  const discounts = [0.25, 0.50, 0.75, 0.90];

  const specialsCoupons = [{coupon: 'PLNetwork24', discount: 0.25}, {coupon: 'IWANTTOBUILD', discount: 0.75}];

  for (const name of names) {
    for (let i = 0; i < codes.length; i++) {
      const generatedCoupon = `${name}${codes[i]}`;
      if (generatedCoupon === coupon) {
        return discounts[i];
      }
    }
  }

  const hasSpecialCoupons = specialsCoupons.find(c => c.coupon === coupon);
  if(hasSpecialCoupons){
    return hasSpecialCoupons.discount;
  }

  return 0;
};

export const isMobile = () => {
  let navegador = navigator.userAgent;
  if (navegador.match(/Android/i) || navegador.match(/webOS/i) || navegador.match(/iPhone/i) || navegador.match(/iPad/i) || navegador.match(/iPod/i) || navegador.match(/BlackBerry/i) || navegador.match(/Windows Phone/i)) {
    return true;
  } else {
    return false;
  }
};

export const expiredPayment = (payment) => {
  const now = dayjs();
  const expiration = dayjs(payment.expiration_time).subtract(3, 'h');
  console.log('expiredPayment', now.toISOString(), expiration.toISOString(), now > expiration);
  if(now && expiration){
    return now > expiration;
  }
  return false;
};