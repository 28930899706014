
import { useState } from 'react';
import { createContext } from 'react';
import { STATUS_PAYMENT } from '../assets/Constants';
import { getItemStorage, setItemStorage } from '../helpers/storage';

export const PaymentContext = createContext(null);

const tokenSelectedStorage = getItemStorage('token_selected');
const tokenParse = tokenSelectedStorage && JSON.parse(tokenSelectedStorage);

export const PaymentProvider = ({children}) => {
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [currencies, setCurrencies] = useState(null);
  const [selectedToken, setSelectedToken] = useState({ token: tokenParse, prev: null});
  const [status, setStatus] = useState(STATUS_PAYMENT.pending);

  const setTokenChain = (token) => {
    setSelectedToken(data => ({token, prev: data.token}));
    setItemStorage('token_selected', JSON.stringify(token));
  };

  const resetPaymentRequest = (resetToken = false) => {
    setPaymentRequest(null);
    resetToken && setSelectedToken({ token: null, prev: null});
    setStatus(STATUS_PAYMENT.pending);
  };

  return(
    <PaymentContext.Provider 
      value={{ 
        paymentRequest, 
        setPaymentRequest,
        selectedToken,
        setSelectedToken: setTokenChain,
        status,
        setStatus,
        resetPaymentRequest,
        setCurrencies,
        currencies
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};