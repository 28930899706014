import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import Card from '../Card/Card';

const ItemCatalogo = ({ children, item, onPressCard, handleAdd, handleSubs, selected, order_count, disabled, style, boxStyle }) => {
  // const orderItem = order.find(i => i.id === item.id);

  return (
    <Box sx={{ ...boxStyle, width: '100%' }}>
      <Card selected={selected} onPress={() => (!disabled && onPressCard) && onPressCard(item)} disabled={disabled} style={{ ...style, cursor: !disabled ? 'pointer' : null, minHeight: '52px', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', width: '100%' }}>

          {children}

          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1 }}>
            {
              handleSubs && (
                <IconButton sx={{ fontSize: '33px' }} onClick={() => handleSubs(item)}>
                  -
                </IconButton>
              )
            }

            {
              order_count && (
                <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>{order_count}</Typography>
              )
            }

            {
              handleAdd && (
                <IconButton sx={{ fontSize: '33px' }} onClick={() => handleAdd(item)}>
                  +
                </IconButton>
              )
            }
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

const Subtitle = ({ item, showPrice = true, ...rest }) => {

  return (
    <ItemCatalogo item={item} {...rest}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>{item.title}</Typography>
          <Typography sx={{ fontSize: '14px' }}>{item.subtitle} {item.link}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '15px' }}>
          {
            showPrice && (
              <Typography sx={{ fontSize: '18px', fontWeight: '600', color: 'rgba(0,0,0,.7)' }}>${item.price}</Typography>
            )
          }
        </Box>
      </Box>
    </ItemCatalogo>
  );
};

const Normal = ({ attendee = 'price', item, showPrice = true, discount, icon, ...rest }) => {
  
  return (
    <ItemCatalogo item={item} {...rest}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>{item.title}</Typography>
          {rest.subtitle && (
            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>{rest.subtitle}</Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '15px', gap: 2 }}>
          {
            icon
          }

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

            {
              showPrice && (
                <Typography
                  sx={{ fontSize: discount ? '14px' : '18px', fontWeight: 600, color: 'rgba(0,0,0,.7)', textDecoration: discount ? 'line-through' : 'none' }}>
                  ${item[attendee]}
                </Typography>
              )
            }

            {
              discount ? (
                <Typography sx={{ fontSize: '18px', fontWeight: '600', color: 'rgba(0,0,0,.7)' }}>${(item.price * (1 - discount))?.toFixed(0)}</Typography>
              ) : null
            }
          </Box>
        </Box>
      </Box>
    </ItemCatalogo>
  );
};

const Ticket = ({ item, showPrice = true, ...rest }) => {
  return (
    <ItemCatalogo item={item} style={{ position: 'relative' }} {...rest}>
      {/* <Box
        sx={{
          position: 'absolute',
          top: '8px',
          bottom: '8px',
          left: '-8px',
          width: '16px',
          height: 'calc(100% - 16px)',
          backgroundColor: '#fff',
          border: '2px dashed rgba(0, 0, 0, .7)',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '8px',
          bottom: '8px',
          right: '-8px',
          width: '16px',
          height: 'calc(100% - 16px)',
          backgroundColor: '#fff',
          borderRadius: '50%',
          border: '2px dashed rgba(0, 0, 0, .7)',
        }}
      /> */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>{item.title}</Typography>
        {/* <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>{orderItem ? orderItem.cantidad : 0}</Typography> */}
        {/* <Box sx={{
              width: '18px !important',
              height: '18px !important',
              backgroundColor: item.color,
              borderRadius: '100px',
              border: '1px solid rgba(0,0,0,.6)',
              marginLeft: '3px'
            }}></Box> */}
        <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '15px' }}>
          {/* <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>{item.title}</Typography> */}
          {
            showPrice && (
              <Typography sx={{ fontSize: '18px', fontWeight: '600', color: 'rgba(0,0,0,.7)' }}>${item.price}</Typography>
            )
          }
        </Box>
      </Box>
    </ItemCatalogo>
  );
};

ItemCatalogo.Subtitle = Subtitle;
ItemCatalogo.Normal = Normal;
ItemCatalogo.Ticket = Ticket;



export default ItemCatalogo;