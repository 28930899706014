import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { calculateTotalBeforePayment, StepContext } from '../../providers/StepProvider';
import { PaymentContext } from '../../providers/PaymentProvider';
import { FormContext } from '../../providers/FormProvider';
import Card from '../../components/Card/Card';
import { getParam, getPath, getRouteLocation } from '../../helpers/multiuse';
import { Slide } from '@mui/material';
import { STRIPE } from '../../assets/Chains';
import useApi from '../../hooks/useApi';
import usePayment from '../../hooks/usePayment';
import { InputCoupon } from '../../components/CouponInput/CouponInput';
import { InputSubtitle } from '../../components/Typographies';

const TokenList = () => {
  const { setDisabled, disabled, setLoading } = useContext(StepContext);
  const { setSelectedToken, selectedToken, currencies } = useContext(PaymentContext);
  
  const { form, setForm, resetForm, setNotification } = useContext(FormContext);
  const { crecimientoPaymentSpecial } = usePayment();
  const { getCurrenciesMerchant } = useApi();
  const isCrecimiento = getPath() === 'crecimiento';
  const isStatic = getParam('static') === 'true';
  const hasPaymentId = getParam('payment_id');
  const route = getPath();

  const handlePressItem = (token) => {
    let total = calculateTotalBeforePayment(form.reference.attendees, form.discount);
    setForm((prev) => ({
      ...prev,
      total: total
    }));
    setSelectedToken(token);
    if (token && (disabled.prev || disabled.next) && !isStatic) {
      setDisabled(({ prev: (isStatic || hasPaymentId), next: false }));
    }
   
  };

  const handleSpecialCrecimiento = async () => {
    if (route !== 'crecimiento') return;

    if (form.details?.discount_code && form.ars_amount === 0 && (form.details?.freePassport || form.details?.discount === 1)) {
      setLoading(true);
      await crecimientoPaymentSpecial();
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    handleSpecialCrecimiento();
    if (!currencies) getCurrenciesMerchant();
    setDisabled({ prev: (isStatic || hasPaymentId), next: form.discount === 1 ? false : !selectedToken.token });
  }, [form, selectedToken.token]);

  return (
    <Slide direction="left" in={true} mountOnEnter>
      <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '30px', width: '100%', marginTop: '15px' }}>
        <InputCoupon />

        {form.discount !== 1 &&  <StripeMethod />}
        {
          form.discount !== 1 && currencies?.map((i, index) => {
            const selected = (selectedToken.token?.ticker === i.ticker && selectedToken.token.chain_id === i.chain_id);
            if (i.enabled) {
              return (
                <CardToken key={`${i.id}-${index}`} item={i} selected={selected} onPress={handlePressItem} />
              );
            }
          })
        }

      </Box>
    </Slide>
  );
};

const CardToken = ({ item, onPress, selected }) => {
  const media_query = useMediaQuery('(max-width:400px)');

  return (
    <Card direction='row' onPress={() => onPress(item)} selected={selected} style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Card.Image
          src={item.currency_logo}
          style={{ width: '30px' }}
        />
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>

          <Card.Title style={{ fontWeight: '700', fontSize: '15px', maxWidth: 150, width: 'auto' }}>{item.ticker} </Card.Title>
          {item.subticker && <InputSubtitle sx={{ 
            width: media_query ? '80px' : '150px',
            textOverflow: 'ellipsis',
            overflow: 'hidden', 
            whiteSpace: media_query ? 'nowrap' : null,
            marginBottom: '!important 0px' }}>{item.subticker}</InputSubtitle>}
        </Box>
      </Box>
      {
        item.chain_name && (
          <Card.Chip title={item.chain_name} logo={item.chain_logo} selected={selected} />
        )
      }
    </Card>
  );
};

const StripeMethod = () => {
  const { setSelectedToken, selectedToken } = useContext(PaymentContext);
  const { form } = useContext(FormContext);
  const { createPaymentStripe } = useApi();
  const { step, setStep } = useContext(StepContext);

  const handlePressStripe = () => {
    setSelectedToken(STRIPE);
  };
  const handleClickPayment = useCallback(async () => {
    
    let isDevelop = process.env.REACT_APP_DEVELOPMENT === 'true' ? true : false;
    if (step === 3 && selectedToken.token.id === STRIPE.id) {
      const paymentStripe = await createPaymentStripe({...form, reference: { ...form.reference, success_url: `${origin}/success`, cancel_url: `${origin}/edge-city`, test: isDevelop }});
      if (paymentStripe.data.url) {
        window.open(paymentStripe.data.url, '_self');
      }
    }
  },[form, selectedToken]);

  useEffect(() => {
    const buttonPayment = document.getElementById('ButtonNextPayment');
    buttonPayment.addEventListener('click', handleClickPayment);
    return () => buttonPayment.removeEventListener('click', handleClickPayment);
  }, [selectedToken.token, form]);

  return (
    <CardToken key={STRIPE.id} item={STRIPE} selected={selectedToken.token?.id === STRIPE.id} onPress={handlePressStripe} />
  );
};

export default TokenList;