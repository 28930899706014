import { useContext, useEffect, useState } from 'react';
import { Alert, Box, Snackbar, useTheme } from '@mui/material';
import { FormContext } from '../../providers/FormProvider';

const NotificationSnack = () => {
  const { notification, setNotification } = useContext(FormContext);
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const TYPES = {
    default: '#f2bb16',
    error: '#ed7351'
  };

  useEffect(() => {
    if (notification?.msg !== '') {
      setOpen(true);
      setTimeout(() => {
        setNotification({ msg: '', type: 'default' });
      }, 3200);
    }
    else if (notification?.msg === '' && open) {
      setOpen(false);
    }
  }, [notification]);

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        message={notification.msg}
        sx={{ marginBottom: '60px' }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={'error'}
          variant="filled"
          sx={{
            width: '100%',
            padding: '12px ',
            backgroundColor: TYPES[notification.type] ? TYPES[notification.type] : TYPES.default,
            color: '#000',
            border: 'none',
            // boxShadow: theme.shadows.light,
            fontWeight: '400',
            borderRadius: theme.shape.borderRadius
          }}
        >
          {notification.msg}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NotificationSnack;