import axios from 'axios';
import { useContext } from 'react';
import { FormContext } from '../providers/FormProvider';
import { PaymentContext } from '../providers/PaymentProvider';
import { STATUS_PAYMENT, URL_API_CRECIMIENTO } from '../assets/Constants';
import { setItemStorage } from '../helpers/storage';
import { StepContext } from '../providers/StepProvider';

const useAirtable = () => {
  const { setForm } = useContext(FormContext);
  const { setStatus } = useContext(PaymentContext);
  const { setLoading } = useContext(StepContext);

  const setAttendee = (attendee) => {
    const {
      _id,
      name,
      email,
      Country: country,
      'Contact (Telegram)': telegram_contact,
      'Q1 Checkout': question_1,
      'Q2 Checkout': question_2,
      'Discount percentage': discount,
      'Free week': freeWeek,
      'Discount Code': discount_code,
      'Free passport': freePassport
    } = attendee;
    const details = { name, email, country, telegram_contact, question_1, question_2, discount, discount_code, freeWeek, freePassport };
    setForm(prev => ({ ...prev, details, reference: { ...prev.reference, discount, discount_code, email, name, attendee_id: _id, origin } }));
    setItemStorage('attendee_id', _id);
  };

  const getAttendee = async (hash) => {
    try {
      setLoading(true);
      const res = await axios.get(`${URL_API_CRECIMIENTO}/attendees/${hash}`);
      if (res.status === 200) {
        setAttendee(res.data);
        return res.data;
      }
    } catch (e) {
      console.log('Error get Attende', { ...e }, e);
      setStatus(STATUS_PAYMENT.error);
    } finally {
      setLoading(false);
    }
  };

  const postAttendee = async (data) => {
    try {
      setLoading(true);
      const res = await axios.post(`${URL_API_CRECIMIENTO}/attendees`, data);
      if (res.status === 201) {
        setAttendee(res.data.fields);
      }
      return res;
    } catch (e) {
      console.log('Error post Attende', { ...e }, e);
      setStatus(STATUS_PAYMENT.error);
      return ({ status: 400, data: null });
    } finally {
      setLoading(false);
    }
  };

  const getUserThirdweb = async (email, address) => {
    if (!email || !address) return;

    const res = await axios.get(`${URL_API_CRECIMIENTO}/morfi/claim/?email=${email}&address=${address}`);
    return res.data;
  };

  const getCountries = async () => {
    try {
      const res = await axios.get(`${URL_API_CRECIMIENTO}/countries`);
      if (res.status === 200) {
        return res.data;
      }
    } catch (e) {
      console.log('Error get countries', { ...e }, e);
      setStatus(STATUS_PAYMENT.error);
    }
  };

  return ({ getAttendee, postAttendee, getCountries, getUserThirdweb });
};

export default useAirtable;