import { useState, createContext, useContext, useCallback } from 'react';
import { PaymentContext } from './PaymentProvider';
import { getRouteLocation } from '../helpers/multiuse';
import { useActiveWallet } from 'thirdweb/react';
import { getDetailsTransactionToken } from '../helpers/filters';
import { FormContext } from './FormProvider';
import { weeklyDiscounts } from '../Routes/Crecimiento/Constants';
import { ClientContext } from './ClientProvider';

export const StepContext = createContext(null);

function toFixedNumber(num, digits, base){
  const pow = Math.pow(base ?? 10, digits);
  return Math.round(num*pow) / pow;
}

export const calculateTotalBeforePayment = (values, discount = 0, isObj) => {
 
  let foundPatron = values.find(item => {
    if (item.category === 'patron' || (item.category === 'standard' && item.weeks[0]?.title === 'Patron')) {
 
      return item;
    }
    return undefined;
  }) ;
  let findPlan = values?.find(item => ['scholar', 'builder', 'patron', 'standard'].includes(item.category));
  let planTotalPrice = findPlan?.price;
  let weekDiscount = 0;
  let weekDiscountAmount = 0;
  if(findPlan?.weeks?.length === 2) {
    weekDiscount = weeklyDiscounts['twoWeeks'];
  }
  if(findPlan?.weeks?.length === 3) {
    weekDiscount =  weeklyDiscounts['threeWeeks'];
  }
  let totalAmount = values?.reduce((a, b) => {
    return a + b.price;
  }, 0);
  if(weekDiscount) {
    weekDiscountAmount = toFixedNumber((((planTotalPrice * weekDiscount) / 100) * 100), 2);
    totalAmount -= weekDiscountAmount;
  }

  let discountTotal = (totalAmount * (discount * 100)) / 100;

  let discountedAmount = totalAmount - discountTotal;
  let vat = toFixedNumber(((discountedAmount * 7) / 100), 2);

  if(!foundPatron) {
    discountedAmount += vat;
  }
  
  if (!isObj) {
    return discountedAmount;
  } else {
    return {
      referral_code: discountTotal, // DESCUENTO POR CUPON
      discount: weekDiscountAmount,// DESCUENTO X SEMANAS
      vat: foundPatron ? 0 : vat // IMPUESTO VAT
    };
  }
};

export const StepProvider = ({children}) => {
  const { selectedToken: { token }, paymentRequest } = useContext(PaymentContext);
  const { form: { reference, discount }, setForm} = useContext(FormContext);
  const [step, setStep] = useState(0);
  const [disabled, setDisabled] = useState({prev: true, next: true});
  const [loading, setLoading] = useState(false);
  const routeSteps = getRouteLocation();
  const activeWallet = useActiveWallet();
  const [validations, setValidations] = useState(() => () => undefined);
 
  const handleNext = async (createPayment, transfer) => {
    
    if(validations()) {
      return validations();
    }
    if(disabled.next){
      return;
    }
    setValidations(() => () => undefined);
    if (routeSteps?.routes[step]?.createPaymentRequest) {
      let total = calculateTotalBeforePayment(reference.attendees, discount);
      
      setForm((prev) => ({...prev, total: total}));
      setStep(prev => prev + 1);
      if(total === 0) {
        setDisabled({prev: false, next: false});
      } else {
        setLoading(true);
        await createPayment();
        setDisabled({prev: false, next: true});
        setLoading(false);
      }
    } else if (routeSteps?.routes[step]?.pay) {
      if(discount === 1) {
       
        return await createPayment();
      } else if (activeWallet && token.evm_compatible) {
        transfer();
      } else if (token.ticker?.toLowerCase() === 'btc') {
        const tokenTransaction = getDetailsTransactionToken(token, paymentRequest);
        window.open(`lightning:${tokenTransaction.address}`, '_blank');
      }
    } else {
      setStep(prev => prev + 1);
      setDisabled(params => ({...params, prev: false}));
    }
  };



  const handlePrev = () => {

    if(disabled.prev){
      return;
    }

    if (step > 0) {
      if(step === 1){
        setDisabled({prev: true, next: false});
      }
      setStep(prev => prev - 1);
    }
  };

  const resetSteps = () => {
    setStep(0);
    setDisabled({prev: true, next: false});
    setLoading(false);
  };


  return(
    <StepContext.Provider 
      value={{
        disabled, 
        setDisabled,
        setStep,
        step,
        loading, 
        setLoading,
        handleNext,
        handlePrev,
        resetSteps,
        setValidations
      }}
    >
      {children}
    </StepContext.Provider>
  );
};