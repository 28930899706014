import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { calculateTotalBeforePayment, StepContext } from '../providers/StepProvider';
import api from '../api';
import { STATUS_PAYMENT } from '../assets/Constants';
import { getDetailsTransactionToken } from '../helpers/filters';
import { FormContext, updatedAttendees } from '../providers/FormProvider';
import { ERC20_ABI } from '../assets/Contracts';
import { PaymentContext } from '../providers/PaymentProvider';
import { getContract, prepareContractCall, prepareTransaction, sendAndConfirmTransaction } from 'thirdweb';
import useClient from './useClient';
import { useActiveAccount, useActiveWalletChain } from 'thirdweb/react';
import { translate } from '../translate';
import { getRouteLocation } from '../helpers/multiuse';
import { PAYMENT_METHODS } from '../assets/RoutesConfig';
import { removeItemStorage, setItemStorage } from '../helpers/storage';
import axios from 'axios';
import useChain from './useChain';
import { SUPPORTED_CHAIN } from '../assets/Chains';
import useApi from './useApi';

/*
  form: {
    ars_amount: int -> precio en pesos,
    tokens: array -> array de tokens permitidos,
    memo: string -> Nota para la invoice btc,
    catalogo: array -> array del catalogo completo,
    details: Object -> objeto de formulario para registrarse,
    reference: {
      total: int -> precio total que va a pagar antes de aplicar descuento, en pesos o en usd,
      discount: int -> descuento,
      order: array -> array de items de catalogo, carrito de compras.
    }
    {...}
  }
*/

const usePayment = () => {
  const { setStatus, setPaymentRequest, paymentRequest, selectedToken: { token: selectedToken }, resetPaymentRequest } = useContext(PaymentContext);
  const { setLoading, setDisabled, resetSteps } = useContext(StepContext);
  const { form, setForm, resetForm, setNotification } = useContext(FormContext);
  const refetchPayment = useRef(true);
  const client = useClient();
  const activeAccount = useActiveAccount();
  const routeConfig = getRouteLocation();
  const { handleSwitchChain } = useChain();

  useEffect(() => {
    refetchPayment.current = true;
    return () => refetchPayment.current = false;
  }, []);

  const resetOrderPayment = () => {
    resetSteps();
    resetPaymentRequest();
    resetForm();
    removeItemStorage('payment_id');
    removeItemStorage('attendee_id');
  };

  const getRates = async () => {
    try {
      const res = await api.get('/rates');
      return res.data;
    } catch (e) {
      console.log('Error get rates', e);
    }
  };

  const checkRefetchPayment = (amountPayment) => {
    const orderUserDif = form.reference && (JSON.stringify(form.reference) !== JSON.stringify(paymentRequest?.reference));
    if (paymentRequest?.ars_amount !== amountPayment || orderUserDif) {
      return true;
    }
    return false;
  };

  const linkHashPayment = async (hash) => {
    try {
      const response = await api.put(`/payment_requests/${paymentRequest.id}/link_hash`, { hash, chain_id: selectedToken.chain_id, ticker: selectedToken.ticker });
      const status = response?.status;

      if (status === 404) {
        setNotification({ msg: translate.hashNotFound });
      } else if (status === 200) {
        setPayment(response.data);
      }
    } catch (e) {
      console.log('catch link hash', e);
    }
  };

  const setPayment = (payment) => {
    setForm(prev => ({
      ...prev,
      ars_amount: payment.ars_amount,
      reference: payment.reference
    }));
    setPaymentRequest(payment);
    setStatus(STATUS_PAYMENT[payment.status?.toLowerCase()]);
    setItemStorage('payment_id', payment.id);
  };

  const calculateAmountPaymentMethod = async () => {
    if (routeConfig.paymentMethod === PAYMENT_METHODS.ars) {
      return form.ars_amount;
    } else if (routeConfig.paymentMethod === PAYMENT_METHODS.usd) {
      const rates = await getRates();
      if (rates && rates['USDT']) {
        let total = calculateTotalBeforePayment(form.reference.attendees, form.discount);
        const amount = total * rates['USDT'];
        return amount;
      }
    }
  };

  const controlPaymentRequest = async () => {
    setLoading(true);

    // if (form.details?.discount_code && form.ars_amount === 0 && (form.details?.freePassport || form.details?.discount === 1)) {
    //   await crecimientoPaymentSpecial();
    //   setLoading(false);
    //   return;
    // }
    if(form.discount === 1) {
      return await refetchFreePayment();
    }
    if(selectedToken?.ticker === 'Credit Card') {
      return;
    }
    const amountPayment = await calculateAmountPaymentMethod();
    if (amountPayment && selectedToken && (!paymentRequest || checkRefetchPayment(amountPayment))) {
      await newPaymentRequest(amountPayment);
      setLoading(false);
      return;
    }

    await updatePaymentRequest();
    setLoading(false);
    return;
  };

  const updatePaymentRequest = async () => {
    // cambiar con cambio de code
    try {
      const paramsRequest = {
        ticker: selectedToken.ticker,
        chain_id: selectedToken.chain_id
      };

      if (!paramsRequest) return;

      const hasTransaction = paymentRequest.transactions?.find(tx => (tx.coin === paramsRequest.ticker && tx.chain_id === paramsRequest.chain_id));

      if (hasTransaction) return;

      const res = await api.put(`/payment_requests/${paymentRequest.id}/add_transaction`, paramsRequest);
      if (res.status === 200) {
        setPayment(res.data);
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const newPaymentRequest = async (amountPayment) => { //memo: 'Aleph, Ciudad de Crecimiento tickets'
    let attendees = form.reference.attendees;


    // console.log(updatedAttendees);
    const parsedReference = {
      ...form.reference,
      attendees: updatedAttendees(attendees)
    };
    // cambiar esto si es necesario updatear
    const paramsRequest = {
      coins: [{ ticker: selectedToken.ticker, chain_id: selectedToken.chain_id }],
      ars_amount: amountPayment,
      memo: form.memo,
      reference: parsedReference
    };

    try {
      const res = await api.post('/payment_requests', { ...paramsRequest });
      if (res && res.status === 201) {
        setPayment(res.data);
        return res.data;
      } else if (res && res.status !== 404) {
        setTimeout(() => newPaymentRequest(amountPayment), 2000);
      }
    } catch (e) {
      console.log('Error payment', e);
      // setTimeout(() => newPaymentRequest(amountPayment), 2000);
    }
  };

  const crecimientoPaymentSpecial = async () => {
    const res = await axios.post('https://crecimiento.simplefi.tech/payments/referral_code', form.reference);
    removeItemStorage('attendee_id');
    removeItemStorage('payment_id');
    if (res.status === 201) {
      setForm(prev => ({
        ...prev,
        ars_amount: 0,
        reference: form.reference
      }));
      setStatus(STATUS_PAYMENT['approved']);
    }
  };

  const getPaymentRequest = async (payment_id) => {
    
    if (!paymentRequest) {
      setLoading(true);
      try {
        const response = await api.get(`/payment_requests/${payment_id}`);
        if (response && response.status === 200) {
          const data = response.data;
          setPayment(data);
          setLoading(false);
        } else {
          if (response && (response.status === 404 || response.status === 401)) {
            resetOrderPayment(false);
          } else if (response && response.status !== 404 && response.status !== 403) {
            setTimeout(() => getPaymentRequest(payment_id), 2000);
          }
        }
        setLoading(false);
        return response;
      }
      catch (e) {
        console.log('Error');
      }
    } else {
      return paymentRequest;
    }
  };

  const transfer = async () => {
    const transaction = getDetailsTransactionToken(selectedToken, paymentRequest);
    if (transaction && paymentRequest.status === 'pending') {
      setStatus(STATUS_PAYMENT.in_process);

      const decimals = transaction.decimals;
      const amount = BigInt(Math.round(transaction.price_details.final_amount * Math.pow(10, decimals)));

      try {
        const switched = await handleSwitchChain();

        if (!switched) {
          setStatus(STATUS_PAYMENT.pending);
          return;
        }

        if (transaction.contract_address) {
          await transferToken(transaction.address, amount);
        } else {
          await transferMainnet(transaction, amount);
        }
      } catch (e) {
        setStatus(STATUS_PAYMENT.pending);
        if (e.code === 4001) {
          setNotification({ msg: 'User rejected the request.' });
        } else if ( e === 'DOMException: signal is aborted without reason') {
          setNotification({ msg: e.stack });
        } else { // if (e.message?.includes('transfer amount exceeds balance'))
          setNotification({ msg: translate.transactionErrorFunds });
        }
        console.log('error', e, { ...e });
      }
    }
  };

  const refetchFreePayment = async () => {
    let attendees = form.reference.attendees;
   


    // console.log(updatedAttendees);
    const parsedReference = {
      ...form.reference,
      attendees: updatedAttendees(attendees)
    };

    // cambiar esto si es necesario updatear
    const paramsRequest = {
      coins: null,
      // ars_amount: amountPayment,
      memo: form.memo,
      reference: parsedReference
    };

    try {
      const res = await axios.post(`${process.env.REACT_APP_EDGE_PATH}/payments/payment_free`, {...paramsRequest}, { headers: { Authorization: process.env.REACT_APP_TOKEN } });
      if (res && res.status === 201 || res.status === 200) {
        setLoading(false);
        setPayment({
          ...res.data,
          ars_amount: 0,
          reference: paramsRequest
        });
        return res.data;
      } 
    } catch (e) {
      setLoading(false);
      console.log('Error payment', e);
      // setTimeout(() => newPaymentRequest(amountPayment), 2000);
    }
  };

  const transferToken = async (address, amount) => {
    const findChain = SUPPORTED_CHAIN.find(chain => chain.id === selectedToken.chain_id);

    if (!findChain) return;

    const contract = getContract({
      client,
      chain: findChain,
      address: selectedToken.contract_address,
      abi: ERC20_ABI,
    });

    const contractCall = prepareContractCall({
      contract,
      method: 'function transfer(address _to, uint256 _value)',
      params: [address, amount],
    });

    const response = await sendAndConfirmTransaction({
      transaction: contractCall,
      account: activeAccount,
    });

    // console.log('response', response);

    if (response?.status === 'success') {
      await linkHashPayment(response.transactionHash);
    }
  };

  const transferMainnet = async (detailsTx, amount) => {
    const findChain = SUPPORTED_CHAIN.find(chain => chain.id === selectedToken.chain_id);

    if (!findChain) return;

    const transaction = prepareTransaction({
      // The account that will be the receiver
      to: detailsTx.address,
      // The value is the amount of ether you want to send with the transaction
      value: amount,
      chain: findChain,
      client,
    });

    const receipt = await sendAndConfirmTransaction({
      transaction,
      account: activeAccount,
    });
  };



  const refetchPaymentRequest = () => {
    if (!refetchPayment.current || !selectedToken) return;
    
    api.get(`/payment_requests/${paymentRequest.id}`)
      .then(({ data }) => {
        const transactionSelected = getDetailsTransactionToken(selectedToken, data);
        const status = STATUS_PAYMENT[data.status?.toLowerCase()];

        if (transactionSelected && transactionSelected.status?.toLowerCase() === STATUS_PAYMENT.correct) {
          setStatus(status);
          setLoading(false);
          setPaymentRequest(data);
        } else if (status === STATUS_PAYMENT.expired || status === STATUS_PAYMENT.canceled) {
          setStatus(status);
          setPaymentRequest(data);
          setDisabled(true);
        } else if (transactionSelected.status?.toLowerCase() === 'not_paid') {
          if (!refetchPayment.current) return;
          setTimeout(() => refetchPaymentRequest(), 2000);
        } else {
          console.log('No se pudo completar la transacción');
        }
      }).catch((e) => {
        if (e?.response?.status !== 404) {
          console.log('OCURRIÓ UN ERROR INESPERADO', e);
        }
      });
  };

  return ({ refetchFreePayment, resetOrderPayment, linkHashPayment, getPaymentRequest, newPaymentRequest, transfer, refetchPaymentRequest, setPayment, controlPaymentRequest, crecimientoPaymentSpecial });
};

export default usePayment;
