import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useContext } from 'react';
import dayjs from 'dayjs';
import { TICKETS, TICKETS_TYPES } from '../Crecimiento/Constants';
import { ClientContext } from '../../providers/ClientProvider';
import { calculateTotal, FormContext } from '../../providers/FormProvider';
import { PlanInputs } from './components/PlanInputs';
import { StepContext } from '../../providers/StepProvider';
import { RadioSelect } from '../../components/RadioSelect/RadioSelect';
import { PartnerInfo } from './components/PartnerInfo';
import PlanComponent from './components/PlanComponent';
import { InputSubtitle, InputTitle } from '../../components/Typographies';
import Input from '../../components/Input/Input';
import { Counter } from './components/Counter';
import { parseWeeks } from '../../helpers/parseWeeks';
import { reg } from './Partner';
import { id } from 'date-fns/locale';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const control_discount = (discount, freePassport, currentStep) => {

  if (freePassport && currentStep === 1) {
    return 1;
  }
  return discount;

};


const Children = () => {
  const { client, loadingClient } = useContext(ClientContext);

  const { form: { reference, details }, setForm } = useContext(FormContext);

  const [childrenOrder, setChildrenOrder] = useState(reference.attendees?.filter(item => item.category === 'kid') ?? []);
  const [errors, setErrors] = useState(null);
  const { setDisabled, setValidations, step } = useContext(StepContext);

  const findIds = (arr, order) => {
    let newArr = arr.filter(item => item.category !== 'kid');

    for (const k of order) {
      let found = newArr.find(item => item.id === k.id);
      let foundIndex = newArr.findIndex(item => item.id === k.id);
      const price = k?.weeks?.reduce((a, b) => { return a + b.children; }, 0);

      if (found) {
        newArr[foundIndex] = { ...k, price: price };
      } else {
        newArr.push({ ...k, price: price });
      }
    }

    return [...newArr];
  };

  const checkRegex = useCallback((value, key) => {
    const re = reg[key]?.reg; // Utiliza el operador opcional para evitar el error

    if (reg[key] === undefined) {
      console.error(`Key "${key}" not found in reg object.`);
      return `Validation for ${key} not found.`;
    }

    if (value === '') {
      return `The ${key} is required.`;
    }

    if (!re?.test(value)) {
      return reg[key].msg;
    }

    setErrors((prev) => ({ ...prev, [key]: undefined }));
    return undefined;
  }, [childrenOrder]);

  const checkRegexAge = useCallback((value, key) => {
    const re = reg[key].reg;

    if (value === '') {
      return `The ${key} is required.`;
    }

    if (isNaN(value)) {
      return `The ${key} must be a number.`;
    }

    if (!re.test(value)) {
      return reg[key].msg;
    }

    setErrors((prev) => ({ ...prev, [key]: undefined }));
    return undefined;
  }, [childrenOrder]);

  const checkRegexValidations = useCallback(() => () => {
    const childrenMap = childrenOrder?.find((item, index) => {
      let result = checkRegex(item.name, 'name');
      let resultAge = checkRegexAge(item.age, 'name');
      if (result && resultAge) {
        setErrors((prev) => {
          if (prev) {
            return [
              ...prev,
              {
                key: item.id,
                name: result,
                age: resultAge
              }
            ];
          } else {
            setErrors([{
              key: item.id,
              name: result,
              age: resultAge
            }]);
          }
        });
        return result;
      }
      return result;
    });
    if (childrenMap) {
      return true;
    }
    setErrors(null);
    return undefined;
  }, [childrenOrder]);


  useEffect(() => {
    let noNext;
    setValidations(checkRegexValidations);
    setForm(prev => ({
      ...prev,
      reference: {
        ...prev.reference,
        attendees: findIds(prev?.reference?.attendees, childrenOrder)
      }
    }));
    for (const m of childrenOrder) {

      if (m?.weeks?.length === 0) {
        noNext = true;
        break;
      } else {
        noNext = false;
      }
    }
    setDisabled({ prev: false, next: noNext });
  }, [childrenOrder]);

  const handler = useCallback((value, index) => {

    setChildrenOrder((prev) => {
      const newArr = [...prev];
      newArr[index].weeks = value;
      return newArr;
    });
  }, []);

  const handleName = useCallback((e, index) => {
    const value = e.target.value;
    setChildrenOrder((prev) => {
      const newArr = [...prev];
      newArr[index].name = value;
      return newArr;
    });
  }, []);
  const handleAge = useCallback((e, index) => {
    const value = e.target.value;
    setChildrenOrder((prev) => {
      const newArr = [...prev];
      newArr[index].age = value;
      return newArr;
    });
  }, []);

  const handleAdd = useCallback((value) => {

    if (value === 0) return setChildrenOrder([]);
    if (value < childrenOrder.length) {

      return setChildrenOrder((prevState) => prevState.filter((_, index) => {
        if (index + 1 <= value) {
          return _;
        }
      }));
    } else if (value > childrenOrder.length) {
      return setChildrenOrder((prevState) => [...prevState, {
        id: value,
        week: [],
        category: 'kid',
        name: '',
        age: '',
        price: 0
      }]);

    } else {

      return;
    }
  }, [childrenOrder]);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', marginTop: '12px', gap: '20px' }}>
        <InputTitle>Will children be joining your party?</InputTitle>
        {/* <InputSubtitle>How many children will you bring?</InputSubtitle> */}
        <Counter value={childrenOrder.length} onChange={handleAdd} sx={{ marginLeft: '20px' }} />
        <Box className="animation-div">
          {
            childrenOrder.length > 0 && childrenOrder.map((item, index) => {
              let err = errors?.find(err => err.id === item.key);
              
              return (
                <Box sx={{ paddingBottom: '10px', marginBottom: '10px', gap: '20px', borderBottom: '2px solid #fff' }} key={`parnter-info${index + 1}`}>
                  <InputTitle >Kid {index + 1}</InputTitle>
                  <Input value={item.name} errorMsg={errors?.find(err => err.id === item.key).name} placeholder="Name" size="small" onChange={(e) => handleName(e, index)} style={{ marginBottom: '10px' }} />
                  <Input value={item.age} errorMsg={errors?.find(err => err.id === item.key).age} placeholder="Age" size="small" onChange={(e) => handleAge(e, index)} type="number" />
                  <PlanComponent initialValue={reference.attendees.find(it => it.id === item.id)?.weeks} ticket={client?.['what_type_of_ticket']} attendee="children" order={item.weeks} handler={(e) => handler(e, index)}  />
                </Box>
              );
            })
          }
        </Box>

      </Box>
    </Box>
  );

};

export default Children;


