import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';

export const Counter = ({ limit = 5, value, onChange, sx }) => {
  const [counter, setCounter] = useState(value);
  
  useEffect(() => onChange && onChange(counter), [counter]);
  
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      gap: '10px',
      ...sx
    }}>
  
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#fff',
        justifyContent: 'center',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        width: '30%',
        borderRadius: '10px',
        border: '1px solid #303030',
        boxShadow: '5px 5px 10px 1px rgba(0, 0, 0, 0.2)',
        fontSize: '1.1em',
        fontWeight: 600,
      }}>
        {counter}
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px'
      }}>
        <Button onClick={() => setCounter((prev) => prev < limit ? prev + 1 : prev)} sx={{border: '1px solid #303030',backgroundColor: '#f6f6f6', color: '#000000', padding: 0, minWidth: '32px'}}>
        +
        </Button>
        <Button onClick={() => setCounter((prev) => prev > 0 ? prev - 1 : 0)} sx={{border: '1px solid #303030', backgroundColor: '#f6f6f6', color: '#000000', padding: 0, minWidth: '32px'}}>
        -
        </Button>
      </Box>
    </Box>
  );
};