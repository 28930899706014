import { Box, Button, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { translate } from '../../translate';
import OrderList from '../../components/Order/OrderList';
import ButtonRetro from '../../components/ButtonRetro/ButtonRetro';
import usePayment from '../../hooks/usePayment';
import { PaymentContext } from '../../providers/PaymentProvider';
import XIcon from '@mui/icons-material/X';
import Lottie from 'react-lottie';
import animationData from '../../assets/Icons/success.json';
import { FormContext } from '../../providers/FormProvider';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import ViewTransactionExplorer from '../../components/ViewTransactionExplorer/ViewTransactionExplorer';
import { getParam, getPath } from '../../helpers/multiuse';
import { removeItemStorage } from '../../helpers/storage';
import Balance from '../../components/Balance/Balance';
import {
  TwitterIcon,
  TwitterShareButton,
  // XIcon
} from 'react-share';

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
};

const Success = ({ children, titleApproved, titleNewPayment, name, callbackReset }) => {
  const { resetOrderPayment } = usePayment();
  const isStatic = getParam('static') === 'true';
  const hasPaymentId = getParam('payment_id');

  const handleClickSimpleFi = () => {
    window.open('https://www.simplefi.tech/', '_blank');
  };

  const handleReset = () => {
    resetOrderPayment();
    callbackReset && callbackReset();
  };

  useEffect(() => {
    removeItemStorage('payment_id');
  }, []);

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', gap: 1, }}>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Lottie
          options={defaultOptions}
          height={90}
          width={90}
        />
        <Typography variant='body1' textAlign={'center'} fontSize={'24px'} fontWeight={'800'}>
          {titleApproved}
        </Typography>

        {
          name && (
            <Typography variant='body1' textAlign={'center'} fontSize={'24px'} fontWeight={'800'}>
              {name}!
            </Typography>
          )
        }
      </Box>

      <Box sx={{ flex: 2, padding: '6px' }}>
        {children}
      </Box>

      <Box sx={{ flex: 1, padding: '0 9px 6px 9px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', gap: 2 }}>
        {/* <Typography sx={{ fontSize: '14px', cursor: 'pointer', color: 'blue', textAlign: 'center' }} onClick={handleClickHash}>Payment hash</Typography> */}
        <ViewTransactionExplorer />
        {
          (!isStatic && !hasPaymentId) && (
            <ButtonRetro.Outlined sx={{ width: '100%' }} onClick={handleReset}>{titleNewPayment}</ButtonRetro.Outlined>
          )
        }
        <Typography sx={{ fontSize: '12px', fontWeight: '700', display: 'flex', alignItems: 'center' }}>Payment experience built with <FavoriteRoundedIcon sx={{ fontSize: '9px', color: 'rgba(255,0,0,.6)', margin: '0 3px' }} /> <Box onClick={handleClickSimpleFi}> by SimpleFi</Box></Typography>
      </Box>

    </Box>
  );
};

const Crecimiento = () => {
  const { form: { reference } } = useContext(FormContext);

  return (
    <Success titleApproved={'Welcome to Aleph'} titleNewPayment={'Purchase another membership'} name={reference?.name}>
      <OrderList full />
      {/* <ViewTransactionExplorer /> */}
    </Success>
  );
};

const Pizza = () => {
  const { paymentRequest } = useContext(PaymentContext);

  const handleClick = () => {
    console.log('twitter');
  };

  return (
    <Success titleApproved={translate.paymentApproved} titleNewPayment={translate.newPayment}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '27px', fontWeight: '600' }}>{translate.orderNumber}</Typography>
        <Typography sx={{ fontSize: '27px', fontWeight: '600' }}>{paymentRequest?.order_id}</Typography>
      </Box>
      <OrderList full />
      <Box sx={{ flex: 1, padding: '0 9px', display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', gap: '12px' }}>
        <Typography sx={{ textAlign: 'center', fontWeight: '600' }}>Compartí tu experiencia y participá por una remera Crypto :)</Typography>
        <ButtonRetro.Contained onClick={handleClick}>Compartir en <XIcon sx={{ fontSize: '18px', marginLeft: '14px' }} /></ButtonRetro.Contained>
      </Box>
    </Success>
  );
};

const Default = () => {

  return (
    <Success titleApproved={translate.paymentApproved} titleNewPayment={translate.newPayment}>
      <OrderList />
      <Balance />
    </Success>
  );
};

const Stripe = () => {

  const handleReset = () => {
    window.open(`${origin}/crecimiento`, '_self');
  };

  return (
    <Success titleApproved={translate.paymentApproved} titleNewPayment={translate.newPayment} callbackReset={handleReset}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography sx={{ textAlign: 'center', fontSize: '27px', fontWeight: '700' }}>Congratulations.</Typography>
        <Typography sx={{ textAlign: 'center', fontSize: '18px', fontWeight: '600' }}>You have purchased tickets for Aleph.</Typography>
        <Typography sx={{ textAlign: 'center', fontSize: '18px', fontWeight: '600' }}>You will receive your access tickets via email shortly.</Typography>
      </Box>
    
    </Success>
  );
};

const EdgeStripe = () => {

  const handleReset = () => {
    return;
  };

  return (
    <Success titleApproved={translate.paymentApproved} titleNewPayment={translate.newPayment} callbackReset={handleReset}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
        {/* <Typography sx={{ textAlign: 'center', fontSize: '27px', fontWeight: '700' }}>Congrats, you’re in!</Typography> */}
        <Typography sx={{ textAlign: 'center', fontSize: '18px', fontWeight: '600' }}>Thank you! for your purchase!</Typography>
        <Typography sx={{ textAlign: 'center', fontSize: '18px', fontWeight: '600' }}>We can&apos;t wait to see you in Thailand</Typography>
      </Box>
      <Twitter />
    </Success>
  );
};

const Twitter = () => {

  const TwitterButton = ({description, url}) => (
    <TwitterShareButton style={{
      backgroundColor: 'black',
      color: '#fff',
      display: 'grid',
      gridTemplateColumns: '1fr auto 1fr',
      width: '300px',
      // width: '100%',
      justifySelf: 'center',
      alignItems: 'center',
      borderRadius: '10px',
      padding: '10px 20px',
    }} title={description} url={url}>
      <XIcon color="#fff" size={32} round={true} />
      Share on X
    </TwitterShareButton>
  );

  const BookAccomodationButton = () => (
    <a style={{
      width: '300px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#F14794',
      color: '#fff',
      textDecoration: 'none',
      borderRadius: '10px',
      padding: '10px 20px'
    }} href="https://edgecitylanna.substack.com/p/accommodation-at-edge-city-lanna">Book Accomodation</a>
  );

  const Link = ({link, str}) => ( <a href={link} style={{textDecorationThickness: '3px', textDecorationColor: '#F14794', color: '#F14794', margin: '0px 4px',}}>{str}</a> );
  
  const url = 'https://www.edgecity.live/lanna';
  const description = `
  I'm heading to Edge City Lanna! Just secured my spot and I'm really looking forward to it. If you want to join me, applications are open on the site, Edge City Lanna | Chiang Mai, Thailand`;
  return (
    <Box mt={3} sx={{
      width: '100%',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }}>
      <Typography sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
      }}>
        <span> In the meantime, subscribe to our <Link link="https://edgecitylanna.substack.com/" str="blog"/> and follow us on <Link link="https://x.com/joinedgecity" str="Twitter" /></span>
        <br />
Invite your friends to Lanna by sharing the
        <Link link="https://airtable.com/appci7xgbOZKP1u37/pagSzSTdJZ59n7kIh/form" str="application" />
        <br />
        <TwitterButton description={description} url={url} />
        {/* [application](Airtable | Everyone&apos;s app platform )  */}
        <br />
        Now that you have your ticket don’t forget to book your accomodation!
        <BookAccomodationButton />
      </Typography>
    </Box>
  );
};

Success.Twitter = Twitter;
Success.Default = Default;
Success.Crecimiento = Crecimiento;
Success.Pizza = Pizza;
Success.Stripe = Stripe;
Success.EdgeStripe = EdgeStripe;



export default Success;