import React, { useEffect, useMemo, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import ItemCatalogo from '../../components/Catalog/ItemCatalogo';
import { useContext } from 'react';
import { FormContext } from '../../providers/FormProvider';
import { StepContext } from '../../providers/StepProvider';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import ModalRetro from '../../components/Modal/Modal';
import Input from '../../components/Input/Input';
import ButtonRetro from '../../components/ButtonRetro/ButtonRetro';
import dayjs from 'dayjs';
import { fillArrayDateFromUntil } from '../../helpers/multiuse';
import Loader from '../../components/Loader/Loader';
import useController from '../../hooks/useController';
import { TICKETS, TICKETS_TYPES, TYPES_FORMAT } from './Constants';
import { DoubleDateFromTo } from '../../components/DateField/DateField';
import Passport from './Passport/Passport';
import { ClientContext } from '../../providers/ClientProvider';
import { InputTitle } from '../../components/Typographies';
import { InputCoupon } from '../../components/CouponInput/CouponInput';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const control_discount = (discount, freePassport, currentStep) => {

  if (freePassport && currentStep === 1) {
    return 1;
  }

  return discount;

};

const Catalogo = ({ ticketType, passport }) => {
  const { client } = useContext(ClientContext);
  const { form: { reference, details }, setForm } = useContext(FormContext);
  const [order, setOrder] = useState(reference?.[ticketType] ? reference[ticketType].order : []);
  const { setDisabled, step } = useContext(StepContext);
  const discount_amount = details?.discount; //details.discount
  const freePassport = details?.freePassport;
  const discount = control_discount(discount_amount, freePassport, step);

  const handlePressItem = (item) => {
    if(order?.find(it => it.id === item.id)) return setOrder((prevState) => prevState.filter(it => it.id !== item.id));
    if(['month'].includes(item.type)) return setOrder([item]);
    return setOrder((prevState) => [...prevState, item]);
  };
  const ticketOrigin = 'all';
  const ticketSelection = useMemo(() => TICKETS[ticketOrigin]?.[client.Ticket], [client]);
  const calculatePriceOrder = (order, discount) => {
    let price = 0;
    if (order[0]?.type === 'week') {
      for (let index in order) {
        price = price + order[index].price;
      }
    }
    
    const priceDiscount = (price * (1 - discount)).toFixed(0);
    return parseFloat(priceDiscount);
  };


  useEffect(() => {
    if (order) {
      const orderPrice = calculatePriceOrder(order, discount);
      setForm(prev => ({ ...prev, reference: { ...prev.reference, [ticketType]: { order, total: orderPrice } } }));
      setDisabled({ prev: false, next: false });
    }
  }, [order]);

  if (!reference) {
    return (
      <Loader />
    );
  }

  if (reference) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', height: '100%' }}>
        <Typography sx={{ fontSize: '21px', fontWeight: '700', margin: '6px 0px', marginTop: '21px' }}>{TYPES_FORMAT[ticketType]}</Typography>
        {
          ticketSelection.subtitle && (
            ticketSelection.subtitle(client)
          )
        }
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', marginTop: '12px' }}>
          <InputCoupon />
          <>
            <InputTitle>Book your dates</InputTitle>
            {
              ticketSelection.week?.map((item, index) => {
                const selected = order && order.some(orderItem => orderItem.id === item.id);
                return (
                  <ItemCatalogo.Normal
                    disabled={item.type !== 'month' && order.find(it => ['month'].includes(it.type))}
                    key={item.id}
                    item={item}
                    showPrice={item.type !== 'not'}
                    onPressCard={handlePressItem}
                    discount={discount}
                    icon={item.dates && <DoubleDateFromTo from={item.dates[0]} until={item.dates[1]} />}
                    selected={selected}
                    boxStyle={{
                      paddingBottom: index === 0 ? '10px' : null,
                      marginBottom: index === 0 ? '10px' : null,
                      borderBottom: index === 0 ?'1px solid grey' : null
                    }}
                  />
                );
              })
            }
          </>
        </Box>
      </Box>
    );
  }
};

export default Catalogo;