import { Box, IconButton, Typography, Collapse } from '@mui/material';
import React, { useState, useContext, useEffect } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import usePayment from '../../hooks/usePayment';
import Input from '../../components/Input/Input';
import ButtonRetro from '../../components/ButtonRetro/ButtonRetro';
import WalletConnect from '../../components/WalletConnect/WalletConnect';
import Card from '../../components/Card/Card';
import { copyClipboard, getDetailsTransactionToken } from '../../helpers/filters';
import { translate } from '../../translate';
import { PaymentContext } from '../../providers/PaymentProvider';
import { StepContext } from '../../providers/StepProvider';
import { FormContext } from '../../providers/FormProvider';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { useActiveWallet, useWalletDetailsModal } from 'thirdweb/react';
import { getRouteLocation } from '../../helpers/multiuse';
import { PAYMENT_METHODS } from '../../assets/RoutesConfig';
import { STRIPE, ZKSYNC } from '../../assets/Chains';
import Loader from '../../components/Loader/Loader';
import Balance from '../../components/Balance/Balance';
import useClient from '../../hooks/useClient';

const Payment = () => {
  const { form } = useContext(FormContext);
  const { selectedToken: { token: selectedToken }, paymentRequest } = useContext(PaymentContext);
  const { setDisabled, setStep } = useContext(StepContext);
  const { refetchPaymentRequest } = usePayment();
  const activeWallet = useActiveWallet();
  const transaction = getDetailsTransactionToken(selectedToken, paymentRequest);
  const isMorfiToken = selectedToken?.ticker === 'MORFI';

  const hasDisabledPay = () => {
    if(form.discount === 1) return false;
    if (selectedToken && !selectedToken.evm_compatible) return false;

    if (!activeWallet) return true;

    if (isMorfiToken && activeWallet.id !== 'inApp') return true;

    if (!isMorfiToken && activeWallet.id === 'inApp') return true;

    return false;
  };

  useEffect(() => {
    if (selectedToken?.id !== STRIPE.id && paymentRequest) {
      refetchPaymentRequest();
    }
  }, []);

  useEffect(() => {
    if (!selectedToken && form.discount !== 1) {
      setStep(prev => prev - 1);
      return;
    }
    if (selectedToken?.id !== STRIPE.id) {
      setDisabled({ prev: false, next: hasDisabledPay() });
    }
  }, [selectedToken, activeWallet]);

  const handleClickLemon = () => {
    const url = `https://www.lemon.me/app/cash-out-crypto?network=POLYGON&address=${transaction.address}&currency=USDT&amount=${transaction.price_details.final_amount}&partner=simplefi`;
    window.open(url, '_blank');
  };

  if (!selectedToken || form.total === 0) {
    return <></>;
  }


  if (selectedToken.id === STRIPE.id) {
    return (
      <Loader />
    );
  }

  if (transaction) {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          alignItems: 'center'
        }}
      >
        <Box style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '9px', margin: '15px 0' }}>
          {
            !isMorfiToken && (
              <InfoCard selectedToken={selectedToken} />
            )
          }

          {
            transaction?.evm_compatible && (
              <Box style={{ display: 'flex', width: '100%' }}>
                <ControlWalletConnect isMorfiToken={isMorfiToken} />
              </Box>
            )
          }

          {
            selectedToken.coin === 'USDT' && (
              <Box sx={{ width: '100%' }}>
                <ButtonRetro.Outlined
                  sx={{
                    width: '100%',
                    backgroundColor: 'rgba(0, 240, 104, 1)',
                    padding: '6px 15px',
                    '&:focus': {
                      backgroundColor: 'rgba(0, 240, 104, 1)'
                    }
                  }}
                  onClick={handleClickLemon}
                >
                  {translate.paymentButtonLemon}
                </ButtonRetro.Outlined>
              </Box>
            )
          }
          {/* {
            isMorfiToken && (
              <Box >
                <Balance />
              </Box>
            )
          } */}

          <MountSelectedCard transaction={transaction} />
          {
            !isMorfiToken && (
              <AddressCard transaction={transaction} />
            )
          }

          {/* {
            isMorfiToken && (
              <ShowWalletMorfi />
            )
          } */}

        </Box>
        {/* <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '12px', paddingBottom: '12px' }}>
          <OrderList showTitle />
        </Box> */}
      </Box>
    );
  }
};

const ShowWalletMorfi = () => {
  const detailsModal = useWalletDetailsModal();
  const client = useClient();

  const handleOpenWalletModal = () => {
    detailsModal.open({
      client,
      theme: 'light',
      chains: [ZKSYNC],
      displayBalanceToken: { [ZKSYNC.id]: '0xd9AE1b1Ec5970EaEc4b9EE37E17c7f43D6906deA' }
    });
  };

  return (
    <div style={{ marginTop: '42px', alignSelf: 'center', }}>

      <ButtonRetro.Outlined sx={{ backgroundColor: 'transparent' }} onPress={handleOpenWalletModal}>Show Morfi Wallet</ButtonRetro.Outlined>
    </div>
  );
};

const ControlWalletConnect = ({ isMorfiToken }) => {

  if (isMorfiToken) {
    return (
      <WalletConnect paymentWallet={'inApp'} hideConnected />
    );
  }

  return (
    <WalletConnect />
  );
};

const InfoCard = ({ selectedToken }) => {
  const [hash, setHash] = useState('');
  const [open, setOpen] = useState(false);

  const { linkHashPayment } = usePayment();
  const isBTC = selectedToken?.ticker?.toLowerCase() === 'btc';

  const handleClickOpen = () => {
    setOpen(prev => !prev);
  };

  const handleSendHash = () => {
    setOpen(false);
    linkHashPayment(hash);
  };

  const handleChange = ({ target }) => {
    setHash(target.value);
  };

  return (
    <Card.Outlined backgroundColor={'#f7fabe'} onClick={handleClickOpen}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <InfoOutlinedIcon sx={{ fontSize: '21px', marginRight: '6px' }} />
        {
          isBTC ? (
            <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>
              {translate.payment_info_btc}
            </Typography>
          ) : (
            <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>
              {translate.payment_info_evm}
            </Typography>
          )
        }
        {
          !isBTC && (
            <IconButton>
              {
                open ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />
              }
            </IconButton>
          )
        }
      </Box>

      {
        !isBTC && (
          <Collapse in={open} sx={{ padding: '0px 18px', width: '100%' }}>
            <Input
              size={'minimal'}
              onChange={handleChange}
              placeholder={translate.transactionHash}
              style={{ margin: '15px 0 0 0 ', boxShadow: 'none' }}
              onClick={(event) => event.stopPropagation()}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '9px 15px 0 0' }}>
              <div>
                <ButtonRetro.Text size={'small'} onClick={handleSendHash} disabled={hash === ''}>{translate.send}</ButtonRetro.Text>
              </div>
            </Box>
          </Collapse>
        )
      }
    </Card.Outlined>
  );
};

const AddressCard = ({ transaction }) => {
  const { setNotification } = useContext(FormContext);

  const isBTC = transaction?.coin.toLowerCase() === 'btc';

  const handleCopy = () => {
    copyClipboard(transaction?.address);
    setNotification({ msg: translate.copyAddress, type: '' });
  };

  if (transaction) {
    return (
      <Box style={{ width: '100%' }}>
        <Typography fontSize={16} fontWeight={'bold'} style={{ paddingLeft: 9 }}>{isBTC ? 'Invoice' : translate.send_to}</Typography>
        <Card direction='row' style={{ justifyContent: 'space-between', padding: '3px 8px' }}>
          <Box style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
            <AccountBalanceWalletIcon style={{ width: 27, height: 27, marginRight: '9px' }} />
            <Card.Title fontSize={16} fontWeight={'600'} style={{ direction: 'rtl', overflow: 'hidden', textOverflow: 'ellipsis' }}>{transaction.address}</Card.Title>
          </Box>

          <IconButton onClick={handleCopy} >
            <ContentCopyIcon sx={{ fontSize: '21px' }} />
          </IconButton>
        </Card>
      </Box>
    );
  }
};

const TokenSelectedCard = ({ token, handlePress }) => {
  return (
    <Box style={{ width: '100%' }}>
      <Typography fontSize={16} fontWeight={'bold'} style={{ paddingLeft: 9 }}>Token</Typography>
      <Card direction='row' style={{ justifyContent: 'space-between' }} onPress={handlePress}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Card.Image
            src={token.logo}
            style={{ width: 39, height: 39 }}
          />
          <Card.Title fontSize={21} fontWeight={'600'}>{token.name}</Card.Title>
        </Box>
      </Card>
    </Box>
  );
};

const MountSelectedCard = ({ transaction }) => {
  const { setNotification, form } = useContext(FormContext);
  const routeConfig = getRouteLocation();

  const isBTC = transaction?.coin.toLowerCase() === 'btc';
  const amount = isBTC ? Math.round(transaction?.price_details?.final_amount * 100000000) : transaction?.price_details?.final_amount;
  const rate = transaction?.price_details?.rate?.toLocaleString('es-AR');

  const handleCopy = () => {
    copyClipboard(amount);
    setNotification({ msg: translate.copyAmount, type: '' });
  };

  if (transaction) {
    return (
      <Box style={{ width: '100%' }}>
        <Typography fontSize={16} fontWeight={'bold'} style={{ paddingLeft: 9 }}>{translate.amount}</Typography>
        <Card direction='row' style={{ justifyContent: 'space-between', padding: '3px 8px' }}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Card.Image
              src={transaction.currency_logo}
              style={{ width: 27, height: 27 }}
            />
            {
              isBTC ? (
                <Card.Title fontSize={18} fontWeight={'600'}>{`SAT ${amount}`}</Card.Title>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                  <Card.Title fontSize={18} fontWeight={'600'}>{amount}</Card.Title>
                  <span style={{ marginLeft: '3px', fontWeight: '500', fontSize: '12px' }}>{transaction.coin}</span>
                </Box>
              )
            }
          </Box>

          <IconButton onClick={handleCopy} >
            <ContentCopyIcon sx={{ fontSize: '21px' }} />
          </IconButton>
        </Card>
        {
          routeConfig?.paymentMethod === PAYMENT_METHODS.ars && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingRight: '12px', gap: 1 }}>
              <Typography fontSize={14} fontWeight={'600'} style={{ paddingLeft: 9 }}>Total: ${form.ars_amount?.toLocaleString('es-AR')} ARS</Typography>
              {
                transaction.coin === 'MORFI' ? (
                  <Typography fontSize={14} fontWeight={'600'} style={{ paddingLeft: 9 }}>1 {transaction?.coin} = up to ${rate} ARS</Typography>
                ) : (
                  <Typography fontSize={14} fontWeight={'600'} style={{ paddingLeft: 9 }}>1 {transaction?.coin} = ${rate} ARS</Typography>
                )
              }
            </Box>
          )
        }
      </Box>
    );
  }
};

const Dropdown = ({ children, title, icon }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }} onClick={() => setOpen(!open)}>
        <Typography sx={{ fontSize: '16px', fontWeight: '700' }}>{title}</Typography>
        <IconButton>
          {
            !open ? <KeyboardArrowDownRoundedIcon /> : <KeyboardArrowUpRoundedIcon />
          }
        </IconButton>
      </Box>
      <Collapse in={open} >
        {children}
      </Collapse>
    </>
  );
};

const SelectPayment = ({ children, title, icon }) => {
  const handlePress = () => {
    console.log('a');
  };

  return (
    <Card sx={{}} onClick={handlePress}>
      <Typography sx={{ fontSize: '16px', fontWeight: '700' }}>{title}</Typography>
    </Card>
  );
};

export default Payment;