import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useContext } from 'react';
import dayjs from 'dayjs';
import { TICKETS, TICKETS_TYPES } from '../Crecimiento/Constants';
import { ClientContext } from '../../providers/ClientProvider';
import { FormContext } from '../../providers/FormProvider';
import { PlanInputs } from './components/PlanInputs';
import { StepContext } from '../../providers/StepProvider';
import { RadioSelect } from '../../components/RadioSelect/RadioSelect';
import { PartnerInfo } from './components/PartnerInfo';
import PlanComponent from './components/PlanComponent';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export const reg = {
  'name': {
    reg: /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/,
    msg: 'Only valid characters'
  },
  'email': {
    reg: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
    msg: 'Invalid email'
  },
  'age': {
    reg: /^(?:1[01][0-9]|1[2-9]|[2-9][0-9]?)$/, // Valida edades de 1 a 120
    msg: 'Age must be a valid number between 1 and 120'
  },
};


const Partner = () => {
  const { client } = useContext(ClientContext);
  const { form: { reference, details }, setForm } = useContext(FormContext);
  const [partnerOrder, setPartnerOrder] = useState(reference.attendees?.find(item => item.category === 'partner') ?? {
    category: null,
    weeks: [],
    name: '',
    email: ''
  });
  const [errors, setErrors] = useState(null);
  const { setDisabled, setValidations } = useContext(StepContext);

  const findPartner = (arr, client) => {
    let newArr = arr;
    var foundIndex = newArr.findIndex(x => x.category === client.category);
    if (foundIndex !== -1) {
      newArr[foundIndex] = client;
    } else {
      newArr = [...newArr, client];
    }
    return newArr;
  };
  
  const checkRegex = useCallback((value, key) => {

    const re = reg[key].reg;
    if (value === '') {
      setErrors((prev) => ({ ...prev, [key]: `The ${key} is required.` }));
      return `The ${key} is required.`;
    }
    if (!re.test(value)) {
      setErrors((prev) => ({ ...prev, [key]: reg[key].msg }));
      return reg[key].msg;
    }
    setErrors((prev) => ({ ...prev, [key]: undefined }));
    return undefined;
  }, [partnerOrder]);

  const checkRegexValidations = useCallback(() => () => {
    if (!partnerOrder.category) return undefined;
    let nameResult = checkRegex(partnerOrder.name, 'name');
    let emailResult = checkRegex(partnerOrder.email, 'email');
    if (nameResult || emailResult) {
      return true;
    }
    return undefined;
  }, [partnerOrder]);

  useEffect(() => {
    if(typeof partnerOrder.category === 'undefined') setDisabled({ prev: false, next: false });
    if(typeof partnerOrder.category !== 'undefined' && (!partnerOrder.weeks || partnerOrder.weeks.length === 0)) setDisabled({ prev: false, next: true });
    
    let price = partnerOrder?.weeks?.reduce((a,b) => a + b.partner, 0);
    let obj = {
      ...partnerOrder,
      price: price
    };

    if (obj.category) {
      setForm(prev => ({
        ...prev,
        reference: {
          ...prev.reference,
          attendees: findPartner(prev.reference.attendees, obj)
        }
      }));
    } else {
      setForm(prev => {

        return ({
          ...prev,
          reference: {
            ...prev.reference,
            attendees: prev.reference.attendees.filter(item => item.category !== 'partner')
          }
        });

      });
    }
    // Se setea una funcion para ejecutar a nivel next step
    setValidations(checkRegexValidations);
    if(!checkRegexValidations) setValidations(() => () => undefined);
    if(partnerOrder.weeks.length > 0) setDisabled({ prev: false, next: false });
    
  }, [partnerOrder]);

  const handleChange = (value) => {

    setPartnerOrder((prev) => ({ ...prev, category: value ? 'partner' : undefined }));
  };
  const handler = (value) => setPartnerOrder((prev) => ({...prev, weeks: value}));
  const handleChangeInfo = (value) => {
    setPartnerOrder((prev) => ({ ...prev, ...value }));
  };

  if (reference) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', height: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', marginTop: '12px', gap: '20px' }}>
          <RadioSelect initialValue={reference.attendees?.find(item => item.category === 'partner') ? true : undefined} title="Will a partner be joining you?" onChange={handleChange} />

          {
            partnerOrder.category && (
              <Fragment key="parnter-info">
                <PlanComponent initialValue={reference.attendees?.find(item => item.category === 'partner')?.weeks} ticket={client?.['what_type_of_ticket']} attendee="partner" handler={handler} />
                <PartnerInfo initialValue={{name:partnerOrder?.name, email: partnerOrder?.email}} errors={errors} onChange={handleChangeInfo} />
              </Fragment>
            )
          }
        </Box>
      </Box>
    );
  }
};

export default Partner;

