import { Box } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { InputTitle } from '../../../components/Typographies';
import Input from '../../../components/Input/Input';
import { StepContext } from '../../../providers/StepProvider';

export const PartnerInfo = ({initialValue, onChange, errors}) => {
  const { setValidations } = useContext(StepContext);
  const [value, setValue] = useState(initialValue ? initialValue : {
    name: '',
    email: '',
  });
  

  
  const handleChange = (e, key) => setValue((prev) => ({...prev, [key] :e.target.value}));
 
  console.log(value);
  useEffect(() => onChange && onChange(value), [value]);
 
  return (
    <Box>
      <InputTitle sx={{fontWeight: '600'}}>Partners information</InputTitle>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
      }}>
        <Input value={value.name} size="small" placeholder="Name" errorMsg={errors?.['name']} onChange={(e) => handleChange(e,'name')} />
        <Input value={value.email} size="small" errorMsg={errors?.['email']} placeholder="Email" onChange={(e) => handleChange(e,'email')} />
        {/* <Input size="small" errorMsg={checkRegex('telegram')} placeholder="Telegram" type="tel" onBlur={(e) => handleChange(e,'telegram')} /> */}
      </Box>
    </Box>
  );
  
};