import { createTheme } from '@mui/material';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#dbfee6',
    },
    secondary: {
      main: '#edf2ff',
    },
    background: {
      default: '#fac27f',
      paper: '#2a2a2a',
    },
    text: {
      primary: '#ffffff',
      secondary: '#cccccc',
    },
  },
});

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#dbfee6',
    },
    secondary: {
      main: '#784af4',
    },
    text: {
      primary: '#333333',
      secondary: '#555555',
    },
    card: '#ffda7a'
  },
  shadows: {
    0: '0px 0px 0 0 #000000, inset 0 0 0 1px #000000',
    // minimal: '-2px 2px 0 0 #000000, inset 0 0 0 1px #000000',
    // light: '-3px 3px 0 0 #000000, inset 0 0 0 1px #000000',
    // normal: '-4px 4px 0 0 #000000, inset 0 0 0 2px #000000',
    // dense: '-5px 5px 0 0 #000000, inset 0 0 0 2px #000000',
    16: '-5px 5px 0 0 #000000, inset 0 0 0 2px #000000'
  },
  border: {
    minimal: {
      borderWidth: '2px 2px 6px 6px',
      borderColor: '#000',
      borderStyle: 'solid',
      borderRadius: '15px'
    },
    light: {
      borderWidth: '2px 2px 8px 8px',
      borderColor: '#000',
      borderStyle: 'solid',
      borderRadius: '21px'
    }
  },
  shape: {
    borderRadius: 3
  },
  typography: {
    fontFamily: 'Neue-Regular, Neue, sans-serif'
  }
});
