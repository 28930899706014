import { MdAttachMoney } from 'react-icons/md';
import { IoTicketOutline } from 'react-icons/io5';
import { MdOutlineGeneratingTokens, MdFormatAlignCenter   } from 'react-icons/md';
import { LuWallet2 } from 'react-icons/lu';
import CatalogoPizzaDay from '../Routes/Pizzaday/CatalogoPizzaDay';
import Catalogo from '../Routes/Crecimiento/Catalogo';
import TokenList from '../Routes/Root/TokenList';
import Payment from '../Routes/Root/Payment';
import Success, { EdgeStripe } from '../Routes/Root/Success';
import { FaStaylinked } from 'react-icons/fa6';
import Amount from '../Routes/Root/Amount';
import Form from '../Routes/Crecimiento/Form';
import CatalogoClosed from '../Routes/Crecimiento/CatalogoClosed';
import LandingPage from '../Routes/Crecimiento/LandingPage';
import CreateWallet from '../Routes/Morfi/CreateWallet';
import Deposit from '../Routes/Deposit/Deposit';
import RedirectPage from '../Routes/Redirect/RedirectPage';
import ClientInfo from '../Routes/Stay/ClientInfo';
import { HiOutlineTicket } from 'react-icons/hi';
import { CgRing } from 'react-icons/cg';
import Partner from '../Routes/Stay/Partner';
import { TbMoodKid } from 'react-icons/tb';
import Children from '../Routes/Stay/Kids';
import Food from '../Routes/Stay/components/Food';

const isDevelop = process.env.REACT_APP_DEVELOPMENT === 'true';

export const PAYMENT_METHODS = {
  ars: 'ARS',
  usd: 'USD'
};

export const getParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}; 


export const ROUTES_CONFIG = {
  'edge-city': {
    success: <Success.Default/>,
    paymentMethod: PAYMENT_METHODS.usd,
    routes: [
      { route: 'plan', component:  ClientInfo, step: 0, label: 'Plan', icon: <HiOutlineTicket fontSize={27}/>},
      { route: 'partner', component: Partner, step: 1, label: 'Partner', icon: <CgRing fontSize={27}/>},
      { route: 'children', component: Children, step: 2, label: 'Children', icon: <TbMoodKid fontSize={27}/>},
      { route: 'token', component: TokenList, step: 3, label: 'Payment', icon: <MdOutlineGeneratingTokens fontSize={30} />, selectToken: true, createPaymentRequest: true },
      { route: 'payment', component: Payment, step: 4, label: 'Pay', icon: <LuWallet2 fontSize={30} />, pay: true }
      // { route: 'food', component: Food, step: 3, label: 'Food', icon: <MdOutlineGeneratingTokens fontSize={30} />, selectToken: true, createPaymentRequest: true },

    ]
  },
  'success': {
    success: <Success.Default/>,
    routes: [
      { hideOrderBottom: true, route: 'plan', component: Success.EdgeStripe, step: 0, label: 'Success', icon: <HiOutlineTicket fontSize={27}/>}
    ]
  }
  
};