import { useEffect, useState } from 'react';
import { createContext } from 'react';

export const FormContext = createContext(null);

export const updatedAttendees = (attendees) => {
  let updatedAttendees = attendees?.map(item => {
    if(item?.weeks?.[0].title === 'Patron'){
      item.category = 'patron';
    }
    if(item.weeks?.find(item => typeof item !== 'string')) {
      let obj = {...item};
      let weeks = item.weeks.map(({ key }) => (key));
      if(weeks.find(item => item === 'month')) {
        weeks = ['week1', 'week2', 'week3', 'week4'];
      }
      delete obj.week;
      obj.weeks = weeks;
      return obj;
    } else {
      return item;
    }

    
  });
  return updatedAttendees;
};

const form_defaultValue = {
  ars_amount: 0,
  total: 0,
  tokens: null, 
  discount: 0, // from 0 to 1
  catalogo: null,
  memo: 'Edge City',
  reference: {
    application_id: null,
    attendees: [],
    referral_code: null,
  },
};


export const calculateTotal = ({client, partner = 0, children = 0, discount = 100}) => {
  let total = client + partner + children;
  return Math.floor((total * discount) / 100);

};
export const FormProvider = ({ children }) => {
  const [form, setForm] = useState(form_defaultValue);
  const [notification, setNotification] = useState({ msg: '', type: 'success' });

  const resetForm = () => {
    setForm(form_defaultValue);
  };
  
  return(
    <FormContext.Provider 
      value={{
        notification, 
        setNotification,
        setForm,
        form,
        resetForm
      }}
    >
      {children}
    </FormContext.Provider>
  );
};