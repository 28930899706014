import { Box, Collapse, Divider, IconButton, Typography } from '@mui/material';
import React, { Fragment, useContext, useMemo, useState } from 'react';
import usePayment from '../../hooks/usePayment';
import ButtonRetro from '../ButtonRetro/ButtonRetro';
import { getRouteLocation } from '../../helpers/multiuse';
import { translate } from '../../translate';
import { calculateTotalBeforePayment, StepContext } from '../../providers/StepProvider';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { weeklyDiscounts } from '../../Routes/Crecimiento/Constants.js';
import TotalAmount from '../TotalAmount/TotalAmount';
import { FormContext, FormProvider } from '../../providers/FormProvider';
import { InputSubtitle } from '../Typographies';
import { ClientContext } from '../../providers/ClientProvider.js';

const BottomControlOrder = () => {
  const { form } = useContext(FormContext);
  const { step, handleNext, handlePrev, loading, disabled: { prev, next } } = useContext(StepContext);
  const { controlPaymentRequest, transfer } = usePayment();
  const { client } = useContext(ClientContext);
  const [open, setOpen] = useState(false);

  const routeSteps = getRouteLocation();
  const actualStep = routeSteps?.routes[step];
  const labelNext = actualStep?.pay && form.total !== 0 ? translate.pay : actualStep?.pay && form.total === 0 ? translate.free : translate.continue;

  const handlePressNext = () => {
    
    handleNext(controlPaymentRequest, transfer);
  };

  const handleOpenDrawer = () => {
    setOpen(!open);
  };

  const disablePartner = useMemo(() => {
    switch(typeof client?.partner !== 'undefined') {
    case !client?.partner && step === 1:
      return true;
    case client?.partner && step === 2:
      return true;
    }
    return false;
  },[step, client]);

  return (
    <Box sx={{ padding: '0px 15px 9px 15px', backgroundColor: '#FFF', borderTopRightRadius: '15px', borderTopLeftRadius: '15px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <IconButton onClick={handleOpenDrawer} sx={{ padding: 0, fontSize: '21px' }}>
          {
            !open ? <ExpandLessRoundedIcon sx={{ fontSize: '30px' }} /> : <ExpandMoreRoundedIcon sx={{ fontSize: '30px' }} />
          }
        </IconButton>
      </Box>
     
      <Collapse in={open}>
        {/* <CrecimientoOrderList /> */}
        <EdgeCityDetails />
      </Collapse>
      <Divider />
      <Box sx={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between', padding: '0 12px' }}>
        <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>Total: </Typography>
        <TotalAmount />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '12px' }}>
        <div>
          <ButtonRetro.Outlined onPress={handlePrev} disabled={disablePartner || prev || loading} sx={{ padding: '4px 18px', boxShadow: 'none' }}>Back</ButtonRetro.Outlined>
        </div>
        <div>
          <ButtonRetro.Contained onPress={handlePressNext} disabled={next || loading} id={'ButtonNextPayment'}>{labelNext}</ButtonRetro.Contained>
        </div>
      </Box>
    </Box>
  );
};

export default BottomControlOrder;

export const EdgeCityDetails = () => {

  const { form: { reference, discount } } = useContext(FormContext);
  const attendees = reference.attendees;
  const isPatron = reference.attendees.find(item => {
    if(item.category === 'patron') {
      return true;
    }
    if (item.category === 'standard' && item.weeks[0]?.title === 'Patron') {
      return true;
    }
    return false;
  }) ;

  const prices = useMemo(() => {
    const children = attendees?.reduce((a, b) => {
      if (b.category === 'kid') {
        return a + b.price;
      }
      return a;

    }, 0);
    const plan = attendees?.reduce((a, b) => {
     
      if (['builder', 'standard', 'scholar', 'patron'].includes(b.category)) {
        return a + b.price;
      }
      return a;
    }, 0);

    const partner = attendees?.reduce((a, b) => {

      if (b.category === 'partner') {
        return a + b.price;
      }
      return a;

    }, 0);

    return {
      children,
      plan,
      partner
    };
  }, [reference]);

  const subtotal = useMemo(() => {
    let totalPlanes = prices.children + prices.plan + prices.partner;
    if(discount) {
      let disc = (totalPlanes * discount) / 100;
      return totalPlanes - disc;
    } else {
      return prices.children + prices.plan + prices.partner - calculateTotalBeforePayment(attendees, discount, true).discount; // Descuento semanal
    }
  }, [reference]);


  const discountWeeks = useMemo(() => {
    let findPlan = attendees?.find(item => ['scholar', 'builder', 'patron', 'standard'].includes(item.category));
    
    switch (!!findPlan) {
    case findPlan?.weeks?.length === 2:
      return {
        discount: weeklyDiscounts['twoWeeks'],
        total: (prices.plan * weeklyDiscounts['twoWeeks']) / 100
      };
    case findPlan?.weeks?.length === 3:
      return {
        discount: weeklyDiscounts['threeWeeks'],
        total: (prices.plan * weeklyDiscounts['threeWeeks']) / 100
      };
    default:
      return undefined;
    }
  },[prices, reference]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '6px', width: '100%', }}>
    
     
     
      {
        prices.plan > 0 && (
          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', padding: '0 12px' }}>
            <Box sx={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>

              <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>Plan: </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>$ {prices.plan}</Typography>
              </Box>
            </Box>
            {
              !!discountWeeks && (
                <Box sx={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography sx={{ fontSize: '14px', fontWeight: '500', display: 'flex', flexDirection: 'row', gap: '10px' }}>Long term stay discount<InputSubtitle>{discountWeeks.discount * 100} %</InputSubtitle></Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>$ -{(discountWeeks.total * 100).toFixed(2)}</Typography>
                  </Box>
                </Box>
              )
            }
          </Box>
        )
      }
      {
        prices.partner > 0 && (
          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '0 12px' }}>
            <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>Partner: </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
              <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>$ {prices.partner}</Typography>
            </Box>
          </Box>
        )
      }
      {
        prices.children > 0 && (
          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '0 12px' }}>
            <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>Children: </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
              <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>$ {prices.children}</Typography>
            </Box>
          </Box>
        )
      }
   
     
   
      {
        !!discount && (
          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '0 12px' }}>
            <Typography sx={{ fontSize: '18px', fontWeight: '600', display: 'flex', flexDirection: 'row', gap: '10px' }}>Discount
              <InputSubtitle>{discount * 100} %</InputSubtitle></Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
              <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>$ {(calculateTotalBeforePayment(attendees, discount, true).referral_code).toFixed(2)}</Typography>
            </Box>
          </Box>
        )
      }
      {
        prices.plan > 0 && (
          <Box sx={{ borderTop: '1px solid #d6d6d6', display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '0 12px' }}>
            <Typography sx={{ paddingTop: '10px', fontSize: '18px', fontWeight: '600' }}>Subtotal: </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
              <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>$ {subtotal}</Typography>
            </Box>
          </Box>
        )
      }
      {
        !isPatron && (
          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '0 12px' }}>
            <Typography sx={{ fontSize: '18px', fontWeight: '600', display: 'flex', flexDirection: 'row', gap: '10px' }}>VAT
              <InputSubtitle>(7%)</InputSubtitle></Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
              <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>$ {(calculateTotalBeforePayment(attendees, discount, true).vat)}</Typography>
            </Box>
          </Box>
        )
      }
    </Box>
  );
};