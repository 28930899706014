import React, { useEffect, useState } from 'react';
import { useActiveAccount, useActiveWallet, useConnectedWallets, useDisconnect, useIsAutoConnecting, useSetActiveWallet } from 'thirdweb/react';
import { translate } from '../../translate';
import { Box, Typography } from '@mui/material';
import { createWallet, walletConnect } from 'thirdweb/wallets';
import Card from '../Card/Card';
import './style.css';
import ButtonRetro from '../ButtonRetro/ButtonRetro';
import ConnectButton from '../ConnectButton/ConnectButton';


/*
  state = loading

  0 - Si no hay wallet activa pero tiene una wallet: hace switchWallet automatico

  1 - No esta conectado a ninguna wallet:
    a - Payment with external wallet: Mostrar ConnectWallet external (state = 'connectToExternal')
    b - Payment with inApp wallet: Mostrar ConnectWallet inApp (state = 'connectToInApp')

  2 - Payment with external wallet (paymentWallet = external)
    a - Esta conectado a External wallet (wallet.id != 'inApp'): Mostrar boton conectado. (state = 'connected')
    b - Esta conectado a inApp wallet (wallet.id = 'inApp')
      1 - Tiene otra wallet external conectada: switchWallet (state = 'switchWallet')
      2 - No tiene otra wallet: Connect Button external wallet. (state = 'connectToExternal')

  3 - Payment with inApp wallet (paymentWallet = inApp)
    a - Esta conectado a inApp wallet (wallet.id = 'inApp'): Mostrar boton conectado. (state = 'connected')
    b - Esta conectado a External wallet (wallet.id != inApp):
      1 - Tiene otra wallet inApp conectada: switchWallet. (state = 'switchWallet')
      2 - No tiene otra wallet: Connect Button external wallet. (state = 'connectToInApp')
*/


const PAYMENTS = {
  external: 'external',
  inApp: 'inApp'
};

const WalletConnect = ({ paymentWallet = PAYMENTS.external, hideConnected = false }) => {
  const { disconnect } = useDisconnect();
  const wallet = useActiveWallet();
  const wallets = useConnectedWallets();
  const isAutoConnecting = useIsAutoConnecting();
  const setActiveWallet = useSetActiveWallet();
  const activeAccount = useActiveAccount();
  const [showDisconnect, setShowDisconnect] = useState(false);
  const [stateWallet, setStateWallet] = useState('loading');


  const defaultWallets = [
    createWallet('io.metamask'),
    createWallet('com.trustwallet.app'),
    createWallet('com.coinbase.wallet'),
    walletConnect(),
  ];

  useEffect(() => {
    if (!wallets || isAutoConnecting) return;

    if (!wallet && wallets.length > 0) {
      setActiveWallet(wallets[0]);
      return;
    }

    const isExternalPayment = paymentWallet === PAYMENTS.external;

    if (wallets.length === 0 || !wallet) {
      isExternalPayment ? setStateWallet('connectToExternal') : setStateWallet('connectToInApp'); //1a y 1b
      return;
    }

    // const hasWalletInApp = Boolean(wallets.find(w => w.id === 'inApp'));
    const isConnectedToInApp = wallet?.id === 'inApp';
    const isConnectedToExternal = !isConnectedToInApp;

    const handleSwitchWallet = () => {
      const isConnectedToInApp = wallet.id === 'inApp';
      if (isConnectedToInApp) {
        const walletToSwitch = wallets.find(w => w.id !== 'inApp');
        setActiveWallet(walletToSwitch);
      } else {
        const walletToSwitch = wallets.find(w => w.id === 'inApp');
        setActiveWallet(walletToSwitch);
      }
    };

    if (isExternalPayment) {//2
      if (isConnectedToExternal) { //2a (es external payment, hay wallet connectada y no es inApp)
        setStateWallet('connected');
      } else if (isConnectedToInApp && wallets.find(w => w.id !== 'inApp')) { //2b1 (esta connectado a inApp wallet, y tiene otra wallet conectada que no es inApp)
        handleSwitchWallet();
      } else { //2b2
        setStateWallet('connectToExternal');
      }
      return;
    }

    if (!isExternalPayment) { //3
      if (isConnectedToInApp) { //3a
        setStateWallet('connected');
      } else if (isConnectedToExternal && wallets.find(w => w.id === 'inApp')) {
        handleSwitchWallet();
      } else {
        setStateWallet('connectToInApp');
      }
    }
  }, [wallet, wallets, isAutoConnecting]);


  if (stateWallet === 'connectToInApp') {
    return (
      <ConnectButton.Modal title={'Connect Wallet'} />
    );
  }

  if (stateWallet === 'connectToExternal') {
    return (
      <ConnectButton.Modal title={'Connect Wallet'} wallets={defaultWallets} />
    );
  }

  if (wallet && !hideConnected) {
    return (
      <Box style={{ width: '100%' }}>
        {/* <Typography fontSize={18} fontWeight={'bold'} style={{ paddingLeft: 9 }}>Wallet</Typography> */}
        <Card direction='row' style={{ padding: '9px', display: 'flex', justifyContent: 'space-between' }} onPress={() => setShowDisconnect(prev => !prev)}>
          <Typography fontWeight={'600'} fontSize={'16px'}>{translate.connected}</Typography>
          {
            showDisconnect ? (
              <Box style={{ display: 'flex' }}>
                <ButtonRetro.Text sx={{ padding: '0' }} size={'small'} onClick={() => disconnect(wallet)}>{translate.disconnect}</ButtonRetro.Text>
              </Box>
            ) : (
              <Typography sx={{ fontSize: '16px', fontWeight: '600', direction: 'rtl', textOverflow: 'ellipsis', overflow: 'hidden', width: '120px' }}>{activeAccount.address}</Typography>
            )
          }
        </Card>
      </Box>
    );
  }
};

export default WalletConnect; 