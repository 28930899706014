export const URL_API_CRECIMIENTO = 'https://crecimiento.simplefi.tech';
// export const URL_API_EDGE_CITY = 'https://edgecity.simplefi.tech'; 
// prod: https://api-edgecity.simplefi.tech/docs
export const URL_API_EDGE_CITY = 'https://apidev-edgecity.simplefi.tech';
export const STATUS_PAYMENT = {
  success: 'payment_success',
  approved: 'payment_success',
  pending: 'pending',
  canceled: 'canceled',
  not_paid: 'not_paid',
  correct: 'correct',
  expired: 'expired',
  repaid: 'repaid',
  in_process: 'in_process', //El user esta pagando y espera a recibir el pago
  error: 'error',
  not_found: 'not_found'
};

export const FREE_COUPONS = [{ coupon: 'RAAVEALEPH', discount: 1, freeStep: 1 }, { coupon: 'ALEPHFORMA', discount: 1, freeStep: 1 }];

export const SPECIAL_COUPONS = [
  { coupon: 'PLNetwork24', discount: 0.25 }, 
  { coupon: 'IWANTTOBUILD', discount: 0.75 },
  { coupon: 'PL@ALEPH', discount: 0.7 },
  { coupon: 'ZKSYNCALEPH', discount: 0.7 },
  { coupon: 'AVAXCRECIMIENTO', discount: 0.7 },
  { coupon: '4SEACRECIMIENTO', discount: 0.7 },
  { coupon: 'WORLDCOINALEPH', discount: 0.7 },
  { coupon: 'LEMONBUILDERS', discount: 0.7 },
  { coupon: 'ALEPHSOLANA', discount: 0.7 },
  { coupon: 'COINBASECRECIMIENTO', discount: 0.7 },
  { coupon: 'ALEPHMONA', discount: 0.5 }, 
  { coupon: 'VITAILALEPH', discount: 0.5 }, 
  { coupon: 'FWBCRECIMIENTO', discount: 0.5 }, 
];

export const COUPONS_NAMES = ['EMI', 'JANINE', 'PILI', 'FIO', 'SANTI', 'TULE', 'JUAN', 'MARTIN', 'JAMES', 'DIEGO', 'SKYLAR', 'PATO', 'HANNA'];
export const COUPONS_CODES = ['25GO', '50ALEPH', '75YA', '90CRECI'];
export const COUPONS_DISCOUNTS = [0.25, 0.50, 0.75, 0.90];
