import { useEffect, useContext } from 'react';
import { useActiveWalletChain, useSwitchActiveWalletChain } from 'thirdweb/react';
import { PaymentContext } from '../providers/PaymentProvider';
import { filterChain } from '../helpers/filters';

const useChain = () => {
  const { selectedToken: { token } } = useContext(PaymentContext);
  const walletChain = useActiveWalletChain();
  const switchChain = useSwitchActiveWalletChain();

  const handleSwitchChain = async () => {
    if (token.evm_compatible && walletChain && (walletChain.id !== token?.chain_id)) {
      try {
        const chain = filterChain(token.chain_id);
        const switcher = await switchChain(chain);
        return true;
      } catch (e) {
        console.log('Error switch chain', e);
        return false;
      }
    }
    return true;
  };

  // useEffect(() => {
  //   handleSwitchChain();
  // }, [walletChain, token?.chain_id]);

  return ({ handleSwitchChain });

};

export default useChain;