import { Typography } from '@mui/material';


export const InputTitle = ({ children, sx }) => (
  <Typography
    mb="5px"
    sx={{
      fontSize: '1.1em',
      fontWeight: '500',
      ...sx
    }}
  >
    {children}
  </Typography>
);



export const InputSubtitle = ({children, sx}) => {

  return (
    <Typography
      mb="5px"
      sx={{
        fontSize: '.9em',
        fontWeight: '500',
        ...sx
      }}
    >
      {children}
    </Typography>
  );
};

export const HelperTextError = ({ children , sx}) => {

  return (
    <Typography
      mb="5px"
      sx={{
        fontSize: '.9em',
        fontWeight: '500',
        ...sx
      }}
    >
      {children}
    </Typography>
  );

};