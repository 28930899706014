import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/Icons/simpleLoader.json';

const Loader = ({ full = true }) => {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: full ? '100%' : 'auto',
      margin: 3
    }}>
      {/* <CircularProgress style={{ color: '#ffda7a' }} /> */}
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
        }}
        height={'100%'}
        width={'100%'}
      />
    </Box>
  );
};

export default Loader;