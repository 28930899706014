import { CssBaseline, ThemeProvider } from '@mui/material';
import { lightTheme } from './theme/theme';
import { StepProvider } from './providers/StepProvider';
import './App.css';
import { FormProvider } from './providers/FormProvider';
import { PaymentProvider } from './providers/PaymentProvider';
import Router from './Routes/Router';
import { ThirdwebProvider } from 'thirdweb/react';
import { ClientProvider } from './providers/ClientProvider';
import posthog from 'posthog-js';

posthog.init('phc_BNu0HesAITYOwCdvOEhwycW6otjYCEh223zmGIEfpVb',
  {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'always' // or 'always' to create profiles for anonymous users as well
  }
);

function App() {

  return (
    <ThirdwebProvider>
      <FormProvider>
        <PaymentProvider>
          <StepProvider>
            <ClientProvider>
              <ThemeProvider theme={lightTheme}>
                <CssBaseline />
                <Router />
              </ThemeProvider>
            </ClientProvider>
          </StepProvider>
        </PaymentProvider>
      </FormProvider>
    </ThirdwebProvider>
  );
}

export default App;
