import { Box, Step, StepConnector, StepLabel, Stepper, Typography, stepConnectorClasses, styled, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { STATUS_PAYMENT } from '../../assets/Constants';
import { StepContext } from '../../providers/StepProvider';
import { PaymentContext } from '../../providers/PaymentProvider';
import { getRouteLocation } from '../../helpers/multiuse';
import Loader from '../Loader/Loader';
import BottomControlOrder from './BottomControlOrder';
import ContainerApp from '../Container/ContainerApp';

const StepControl = ({ children, disabled }) => {
  const { step, loading } = useContext(StepContext);
  const { status } = useContext(PaymentContext);

  const routeSteps = getRouteLocation();

  return (
    <ContainerApp style={{ backgroundColor: status === STATUS_PAYMENT.approved ? '#96faac' : routeSteps.backgroundColor ? routeSteps.backgroundColor : '#74acdf', margin: 'auto' }}>
      {
        (status === STATUS_PAYMENT.pending) && (
          <Box style={{ ...styles.stepper }}>
            <Stepper activeStep={step} alternativeLabel connector={<QontoConnector />}>
              {routeSteps?.routes.map(({ label, icon }) => (
                <Step key={label}>
                  <StepLabel sx={styles.labelStep} StepIconComponent={(props) => <QontoStepIcon {...props} icon={icon} />}>
                    <Typography sx={{ fontSize: '9px', fontWeight: '700' }}>{label}</Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        )
      }
      <Box style={styles.childrenBox}>
        {
          (loading || disabled) ? (
            <Loader />
          ) : (children)
        }
      </Box>
      {
        (status === STATUS_PAYMENT.pending && !routeSteps?.routes[step].hideOrderBottom) && (
          <BottomControlOrder />
        )
      }
    </ContainerApp>
  );
};

const styles = {
  stepper: {
    backgroundColor: 'rgba(255,255,255,.9)',
    padding: '6px 0px',
    margin: '9px 12px 3px 12px',
    borderRadius: 9,
    border: '1px solid rgba(100,100,100,.6)',
  },
  labelStep: {
    '.MuiStepLabel-alternativeLabel': {
      marginTop: '3px !important'
    }
  },
  childrenBox: {
    height: '100%',
    overflow: 'auto',
    padding: '0 15px',
    'WebkitScrollbar': {
      display: 'none'
    }
  },
};


const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 17,
    left: 'calc(-50% + 27px)',
    right: 'calc(50% + 30px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'rgba(0,0,0,1)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'rgba(0,0,0,1)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

function QontoStepIcon(props) {
  const { active, completed, icon } = props;

  const IconStyled = React.cloneElement(icon, {
    fontSize: 27,
    color: (active || completed) ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,.2)'
  });

  return (
    <Box style={{ borderRadius: 6, alignItems: 'center', display: 'flex' }}>
      {IconStyled}
    </Box>
  );
}

export default StepControl;