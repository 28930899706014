import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import Input from '../Input/Input';
import { InputSubtitle, InputTitle } from '../Typographies';
import { Box, CircularProgress, Collapse, IconButton, Typography } from '@mui/material';
import useApi from '../../hooks/useApi';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FormContext } from '../../providers/FormProvider';
import ButtonRetro from '../ButtonRetro/ButtonRetro';
import { PaymentContext } from '../../providers/PaymentProvider';
import { RxCrossCircled } from 'react-icons/rx';
import { getParam } from '../../helpers/multiuse';
import { StepContext } from '../../providers/StepProvider';


export const InputCoupon = (props) => {
  const { onChange } = props;
  const [valid, setValid] = useState(null);
  const { form, setForm } = useContext(FormContext);
  const [initialCoupon, setInitialCoupon] = useState(!!getParam('referral_code'));
  const [isLoading, setIsLoading] = useState(false);
  const { payment } = useContext(PaymentContext);
  const { setDisabled } = useContext(StepContext);

  const { checkCoupon } = useApi();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');

  const handleOnBlur = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await checkCoupon(value.toUpperCase());

      if (response.status === 200) {
        setIsLoading(false);
        
        onChange && onChange(response.data);
        setForm(prev => ({ ...prev, discount: response.data.Discount, reference: { ...prev.reference, referral_code: response.data.UID } }));
        
        return setValid(true);
      }
    } catch (error) {
      setIsLoading(false);

      setForm(prev => ({ ...prev, discount: 0, reference: { ...prev.reference, referral_code: null } }));
      setValid(false);
    }
  },[value]);

  const handleInput = (e) => {
    setValid(null);
    setValue(e);
  };
  const handleCouponNull = () => {
    setInitialCoupon(null);
    setForm(prev => ({ ...prev, discount: 0, reference: { ...prev.reference, referral_code: null } }));
  };

  // useEffect(() => {
  //   console.log(form);
  //   if(initialCoupon && form.discount === 1){
  //     console.log('Asd');
  //     setDisabled({ prev: false, next: false});
  //   }
  // },[form, initialCoupon]);

  return (
    <Box sx={{ mb: 2, pb: 1, borderBottom: 1 }}   >
      {
        initialCoupon ? (
          <Box>

            <Typography sx={{ color: '#515151', fontWeight: '700' }} onClick={() => setOpen(!open)} >Discount</Typography>
            <Input endIcon={
              <IconButton sx={{}} onClick={handleCouponNull} >
                <RxCrossCircled color="#676666" size="20px"/>
              </IconButton>
            } disabled value={getParam('referral_code')} style={{fontSize: '.9em', backgroundColor: 'rgb(239, 239, 239', color: '#676666', borderColor: '#a6a6a6'}} />
          </Box>
        ) : (
          <Box sx={{ display: 'flex', backgroundColor: '#E6E6E6', mt: 2, pt: 1.5, pb: 1.5, pl: 1, mb: 1, borderRadius: 4, border: 2, borderColor: '#979797',justifyContent:'space-between' }} >
            <Typography sx={{ color: '#515151', fontWeight: '700' }} onClick={() => setOpen(!open)} >Do you have a refferal code?</Typography>
            <Typography sx={{ color: '#AA3C6E', fontWeight: '500', ml: 1, marginX:'20px', ':hover': { cursor: 'pointer', color: '#BB688D' }, }} onClick={() => setOpen(!open)}>Click here</Typography>
          </Box>

        )
      }
      {/* {open && */}
      <Collapse in={open}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Input placeholder="Code"  onChange={(e) => handleInput(e.target.value)} endIcon={valid && <CheckCircleIcon sx={{ color: 'green' }} />} style={{ width: '100%', height:'50px', marginRight:'10px', fontSize:'19px',  borderColor: valid === false && value !== '' ? '#a50000':'black', }} />
            <ButtonRetro.Contained sx={{ width: '30%' }} disabled={isLoading} type='submit' onPress={handleOnBlur}>{!isLoading ? 'Apply' : <CircularProgress size="15px" />}</ButtonRetro.Contained>
          </Box>
        </Box>
      </Collapse>
      {/* } */}

    </Box>);
};