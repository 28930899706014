import { Box, Switch, Typography } from '@mui/material';
import { InputTitle } from '../../../components/Typographies';
import { DoubleDateFromTo } from '../../../components/DateField/DateField';
import ItemCatalogo from '../../../components/Catalog/ItemCatalogo';
import dayjs from 'dayjs';
import { childrenPrices, partnerPrices, PatronPrice, TICKETS } from '../../Crecimiento/Constants';
import { Fragment, useContext, useMemo, useState } from 'react';
import { ClientContext } from '../../../providers/ClientProvider';
import { FormContext } from '../../../providers/FormProvider';
import { PaymentContext } from '../../../providers/PaymentProvider';
import { getRouteLocation } from '../../../helpers/multiuse';


export const PlanInputs = (props) => {
  const {
    choices,
    order,
    onChange,
    discount,
    attendee
  } = props;

  const { client } = useContext(ClientContext);
  const { form } = useContext(FormContext);
  const [patron, setPatron] = useState(form?.reference?.attendees?.[0]?.category === 'patron' && form?.reference?.attendees?.[0].price === 2500);
  const [secondPatron, setSecondPatron] = useState(form?.reference?.attendees?.[0]?.category === 'patron' && form?.reference?.attendees?.[0].price === 10000);

  const isStandar = client?.['what_type_of_ticket'];


  const handleToggle = (e, price) => {
    if(price === 2500) {
      setPatron(!patron);
      if(secondPatron) setSecondPatron(false);
      onChange({...ticketPatron, price: price});
    }
    if(price === 10000){
      setSecondPatron(!secondPatron);
      if(patron) setPatron(false);
      onChange({...ticketPatron, price: price});
    }
  };



  const ticketPatron = {
    id: 15,
    key: 'month',
    title: 'Patron',
    type: 'month',
    // price: PatronPrice,
    partner: partnerPrices['month'],
    children: childrenPrices['month'],
    dates: ['2024-08-19', '2024-08-25']
  };

  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  });

  const format = (num) => {
    return formatter.format(num).replace('US', '').trim();
  };


  return (
    <Box>
      {isStandar?.includes('tandar') && props?.screen === 'plan' && (
        <Fragment ket="patron-box">
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', backgroundColor: '#E6E6E6', py: 1, borderRadius: 4, border: 1, borderColor: '#979797', mb: 2 }}>
            <Switch color="secondary" checked={patron} onClick={(e) => handleToggle(e, 2500)}></Switch>
            <Typography>Become a patron for {format(PatronPrice)}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', backgroundColor: '#E6E6E6', py: 1, borderRadius: 4, border: 1, borderColor: '#979797', mb: 2 }}>
            <Switch color="secondary" checked={secondPatron} onClick={(e) => handleToggle(e, 10000)}></Switch>
            <Typography>Become a patron for {format(PatronPrice * 4)}</Typography>
          </Box>
        </Fragment>
      )
     
      }
      <InputTitle>Booking Dates</InputTitle>
      {
        choices?.map((item, index) => {
          const selected = order && order.some(orderItem => orderItem.id === item.id);

          return index === 0 ? (
            <Box sx={{
              paddingBottom: '10px',
              marginBottom: '10px',
              borderBottom: '1px solid grey',
            }} >
              <ItemCatalogo.Normal
                disabled={patron || secondPatron}
                key={item.id}
                item={item}
                showPrice={item.type !== 'not'}
                onPressCard={onChange}
                discount={discount}
                subtitle={`${dayjs(item.dates[0]).format('MMM-D')} - ${dayjs(item.dates[1]).format('MMM-D')}`}
                attendee={attendee}
                // icon={item.dates && <DoubleDateFromTo from={item.dates[0]} until={item.dates[1]} />}
                selected={selected}
              />
            </Box>
          ) :
            (
              <ItemCatalogo.Normal
                disabled={patron || secondPatron}
                key={item.id}
                item={item}
                showPrice={item.type !== 'not'}
                onPressCard={onChange}
                discount={discount}
                subtitle={`${dayjs(item.dates[0]).format('MMM-D')} - ${dayjs(item.dates[1]).format('MMM-D')}`}
                attendee={attendee}
                // icon={item.dates && <DoubleDateFromTo from={item.dates[0]} until={item.dates[1]} />}
                selected={selected}

              />
            );
        })
      }
    </Box>
  );
};