import React from 'react';
import ButtonRetro from '../ButtonRetro/ButtonRetro';
import google from '../../assets/Images/logo_google.png';
import email from '../../assets/Images/logo_mail.png';
import { Typography, useTheme, Box, CircularProgress } from '@mui/material';
import { inAppWallet } from 'thirdweb/wallets';
import useClient from '../../hooks/useClient';
import { useAutoConnect, useConnectModal } from 'thirdweb/react';
import WalletConnect from '../WalletConnect/WalletConnect';
import { LACHAIN } from '../../assets/Chains';
import { getPath } from '../../helpers/multiuse';

const connectProps = {
  appMetadata: {
    name: 'SimpleFi',
    url: 'https://pagar.simplefi.tech',
    description: 'Get paid in crypto with the lowest fees'
  },
  showThirdwebBranding: false,
  autoConnect: true,
  walletConnect: { projectId: process.env.REACT_APP_CLIENT_ID }
};

const ConnectButton = ({ children, options }) => {
  const theme = useTheme();

  const wallets = [inAppWallet({ auth: { options } })];

  return (
    <WalletConnect
      wallets={wallets}
      chain={LACHAIN}
      chains={[LACHAIN]}
      onConnect={(wallet) => {
        console.log('on connect', wallet);
      }}
      showAllWallets={true}
      connectButton={{
        label: children,
        style: {
          border: '1px solid #000',
          padding: '12px',
          boxShadow: theme.shadows.light,
          backgroundColor: 'transparent',
          borderRadius: `${theme.shape.borderRadius * 3}px`,
        },
      }}
    />
  );
};

const Google = () => {

  const ButtonStyleLabel = () => <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
    <img src={google} width={24} height={24} />
    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>CREATE WITH GOOGLE</Typography>
  </Box>;


  return (
    <ConnectButton options={['google']}>
      <ButtonStyleLabel />
    </ConnectButton>
  );
};

const Email = () => {

  const ButtonStyleLabel = () => <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', padding: '1px 0' }}>
    <img src={email} width={24} height={18} />
    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>CREATE WITH EMAIL</Typography>
  </Box>;

  return (
    <ConnectButton options={['email']}>
      <ButtonStyleLabel />
    </ConnectButton>
  );
};

const walletsInApp = [inAppWallet({
  hidePrivateKeyExport: true,
  auth: {
    options: ['google', 'email']
  },
  metadata: {
    image: {
      alt: 'SimpleFi Icon',
      height: 42,
      src: 'https://simplefi.s3.us-east-2.amazonaws.com/icon.png',
      width: 42,
    }
  },
})];

const Modal = ({ title = 'Create Wallet', wallets = walletsInApp, size }) => {
  const { connect, isConnecting } = useConnectModal();
  const client = useClient();
  const path = getPath();

  const { isLoading } = useAutoConnect({ client, wallets });

  const handleConnect = async () => {
    try {
      await connect({
        client,
        wallets,
        autoConnect: true,
        showThirdwebBranding: false,
        size: 'compact',
        theme: 'dark',
        appMetadata: {
          name: 'SimpleFi',
          description: 'Get paid in crypto with the lowest fees',
          url: `https://www.pagar.simplefi.tech/${path}`,
          logoUrl: 'https://simplefi.s3.us-east-2.amazonaws.com/icon.png'
        },
        title: 'Authenticate',
        titleIcon: 'https://simplefi.s3.us-east-2.amazonaws.com/icon.png'
      });
    } catch (e) {
      console.log('Error connect');
    }
  };

  if (client) {
    return (
      <ButtonRetro.Outlined size={size} onPress={handleConnect} disabled={isLoading || isConnecting} sx={{ width: '100%' }}>
        {
          (isLoading || isConnecting) ? <CircularProgress style={{ width: '16px', height: '16px', margin: '6px', fontWeight: '600', color: '#000' }} /> : title
        }
      </ButtonRetro.Outlined>
    );
  }
};


ConnectButton.Google = Google;
ConnectButton.Email = Email;
ConnectButton.Modal = Modal;



export default ConnectButton;