import { Typography } from '@mui/material';
import React, { useContext } from 'react';
import { PaymentContext } from '../../providers/PaymentProvider';
import { getDetailsTransactionToken } from '../../helpers/filters';
import { HiOutlineExternalLink } from 'react-icons/hi';

const ViewTransactionExplorer = () => {
  const { paymentRequest, selectedToken: { token } } = useContext(PaymentContext);
  const detailToken = getDetailsTransactionToken(token, paymentRequest);

  const coinExplorer = (chain_name, hash) => {
    const explorers = {
      'Ethereum': `https://etherscan.io/tx/${hash}`,
      'BCH': `https://blockchair.com/bitcoin-cash/transaction/${hash}`,
      'Rootstock Mainnet': `https://explorer.rootstock.io/tx/${hash}`,
      'Polygon': `https://polygonscan.com/tx/${hash}`,
      'LaChain': `https://explorer.lachain.network/tx/${hash}`,
      'OP Mainnet': `https://optimistic.etherscan.io/tx/${hash}`,
      'ZKsync Era Mainnet': `https://explorer.zksync.io/tx/${hash}`
    };

    return explorers[chain_name];
  };

  const handleClickExplorer = () => {
    const chain_name = token.chain_name;
    const link = coinExplorer(chain_name, detailToken?.payments?.[0]?.hash);
    console.log('handle click', { chain_name, detailToken, link });
    link && window.open(link, '_blank');
  };


  if (token && token.ticker.toLowerCase() !== 'btc') {
    return (
      <Typography
        sx={{ fontSize: '12px', fontWeight: '600', color: 'blue', alignItems: 'center', display: 'flex', gap: '6px', cursor: 'pointer' }}
        onClick={handleClickExplorer}
      >
        View transaction in explorer <HiOutlineExternalLink />
      </Typography>
    );
  }

};

export default ViewTransactionExplorer;