import { SUPPORTED_CHAIN } from '../assets/Chains';

const isTest = process.env.REACT_APP_DEVELOPMENT === 'true';

export const filterUnique = (array) => {
  const objetosMap = new Map();
  array.forEach(objeto => {
    objetosMap.set(objeto.ticker, objeto);
  });
  return Array.from(objetosMap.values());
};

export const filterChain = (chain_id) => {
  if(chain_id){
    return SUPPORTED_CHAIN.find(chain => (chain.id === chain_id));
  }
  return null;
};

export const copyClipboard = (text) => {
  navigator.clipboard.writeText(text);
};


export const getDetailsTransactionToken = (selectedToken, paymentRequest) => {
  if(!selectedToken || !paymentRequest) return false;

  const transaction = paymentRequest.transactions?.find(tx => (selectedToken.ticker?.toLowerCase() === tx.coin?.toLowerCase() && selectedToken.chain_id === tx.chain_id));
  
  if(!transaction) return false;

  return ({...selectedToken, ...transaction});
};