
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import styles from './radio.module.css';
import { InputTitle } from '../Typographies';

const DEFAULT_CHOICES = [{id: 0, name: 'Yes', value: true}, {id: 1, name: 'No', value: false}];
export const RadioSelect = (props) => {

  const {
    initialValue,
    choices = DEFAULT_CHOICES,
    sx,
    key = 'name',
    value = 'value',
    onChange,
    direction = 'row',
    title,

  } = props;

  const [inputValue, setInputValue] = useState(initialValue ?? null);
    
  const handleClick = (item) => {
    setInputValue(item);
    onChange(item);
  };
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '70%',
      }}
    >
      {title && <InputTitle>{title}</InputTitle>}
      <Box sx={{
        display: 'flex',
        flexDirection: direction,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        gap: '20px',
        ...sx
      }}>
        {
          choices?.map((item, index) => {
            return (
              <button 
                key={`button-${index}`}
                id={`button-${index}`}
                type="button"
                onClick={() => handleClick(item[value])}
                className={styles['radio-button']}
                style={{
                  backgroundColor: item[value] === inputValue ? '#F14794' : '#fff',
                  color: item[value] === inputValue ? '#fff' : '#F14794',
                  // borderRadius: index === 0 ? '6px 0px 0px 6px' : index === (choices.length - 1) ? '0px 6px 6px 0px' : null
                  borderRadius: '6px'
                }}>
                <InputTitle sx={{marginBottom: 0}}>
                  {item[key]}
                </InputTitle>
              </button>
            );
          })
        }
      </Box>
    </Box>
  );
};