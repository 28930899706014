import { useContext, useEffect, useState } from 'react';
import { StepContext } from '../providers/StepProvider';
import usePayment from './usePayment';
import useApi from './useApi';
import { instance } from '../api';
import { getPath, getRouteLocation } from '../helpers/multiuse';
import useAirtable from './useAirtable';
import { getItemStorage } from '../helpers/storage';
import { PaymentContext } from '../providers/PaymentProvider';
import { STATUS_PAYMENT } from '../assets/Constants';

const useController = () => {
  const [loading, setLoading] = useState(true);
  const { setStep, setDisabled } = useContext(StepContext);
  const { setStatus } = useContext(PaymentContext);
  const { getPaymentRequest } = usePayment();
  const { getCredentials, getLastPayment } = useApi();
  const { getAttendee } = useAirtable();
  const routeConfig = getRouteLocation();

  const urlParams = new URLSearchParams(window.location.search);

  const control_paymentId = async () => {
    const payment_id = urlParams.get('payment_id') || getItemStorage('payment_id');

    if (payment_id) {
      const payment = await getPaymentRequest(payment_id);
      if (payment.status !== 404) {
        const findStep = routeConfig?.routes?.find(el => el.selectToken === true);
        setStep(findStep ? findStep.step : 0);
      } else {
        setStep(0);
      }
      setDisabled(data => ({ ...data, prev: true }));
    }
  };

  const control_hashAirtable = async () => {
    const hash_id = urlParams.get('attendee') || getItemStorage('attendee_id');
    const path = getPath();
    if (hash_id && (path === 'crecimiento' || path === 'passport')) {
      await getAttendee(hash_id);
    }
  };

  const control_staticMerchant = async () => {
    const isStatic = urlParams.get('static') === 'true';
    if (isStatic) {
      const res = await getLastPayment();
      if (res && res.status === 200) {
        setStep(1);
      }
    }
  };

  const controllerParams = async () => {
    const slug = getPath();

    if(slug === 'success') return setLoading(false);

    
    if (slug) {
      getCredentials(slug).then(async (res) => {
        if (res.status === 404) {
          setStatus(STATUS_PAYMENT.not_found);
          setLoading(false);
          return;
        }

        if (res?.data?.access_token) {
          instance.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`;

          //Aca van todos los params que queremos controlar
          await control_paymentId();
          await control_hashAirtable();
          await control_staticMerchant();

          setLoading(false);
        }
      }).catch((e) => {
        console.log('Error token slug', e);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  return ({
    loading,
    control_hashAirtable,
    control_paymentId,
    controllerParams
  });

};

export default useController;