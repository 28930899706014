import { Box } from '@mui/material';
import React from 'react';

const ContainerApp = ({ children, style }) => {
  return (
    <Box sx={{
      maxWidth: 430,
      position: 'relative',
      margin: 'auto',
      backdropFilter: 'blur(12px)',
      backgroundColor: 'rgba(255,255,255,.5)',
      // ...style
    }}>
      {/* <Box sx={{ position: 'absolute', top: 0, left: 0, width: 430, height: '100dvh', backgroundColor: 'rgba(255,255,255, .2)', filter: 'blur(6px)' }}>

      </Box> */}
      <Box style={{ ...styles.container }}>
        {children}
      </Box>
    </Box>

  );
};

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100dvh',
  },
};

export default ContainerApp;