import { useCallback, useContext, useState } from 'react';
import api from '../api';
import { FormContext, updatedAttendees } from '../providers/FormProvider';
import usePayment from './usePayment';
import { PaymentContext } from '../providers/PaymentProvider';
import { translate } from '../translate';
import axios from 'axios';
import { expiredPayment, getParam } from '../helpers/multiuse';
import { calculateTotalBeforePayment, StepContext } from '../providers/StepProvider';


const useApi = () => {
  const { form, setForm, setNotification } = useContext(FormContext);
  const { paymentRequest, setCurrencies, selectedToken: { token: selectedToken } } = useContext(PaymentContext);
  const { setLoading } = useContext(StepContext);

  const { setPayment, resetOrderPayment } = usePayment();

  const getCredentials = (slug) => {
    return api.get(`/merchants/${slug}/checkout_credentials`, { headers: { Authorization: process.env.REACT_APP_TOKEN } });
  };

  const getClient = (id) => {
    return axios.get(`${process.env.REACT_APP_EDGE_PATH}/applications/${id}`, { headers: { Authorization: process.env.REACT_APP_TOKEN } });
    // return api.get(`/applications/${id}`, { headers: { Authorization: process.env.REACT_APP_TOKEN } });
  };

  const checkCoupon = (str) => {
    return axios.get(`${process.env.REACT_APP_EDGE_PATH}/discounts/${str}`, { headers: { Authorization: process.env.REACT_APP_TOKEN } });
  };

  const getCatalogo = async () => {
    setLoading(true);
    const res = await api.get('/catalog_items');
    if (res && res.status === 200) {
      setForm(prev => ({ ...prev, catalogo: res.data.results }));
    }
    setLoading(false);
  };

  const createPaymentStripe = async (form) => {
    setLoading(true);
    let attendees = form.reference.attendees;
    
    let updAttendees = updatedAttendees(attendees);
    let bodyData = { 
      ...form.reference, 
      attendees: updAttendees,
      prices: calculateTotalBeforePayment(updAttendees, form.discount, true)
    };
    
    // cambiar esto si es necesario updatear
    try {
      const response = await axios.post(`${process.env.REACT_APP_EDGE_PATH}/payments/stripe`, {...bodyData});
      return response;
    } catch (e) {
      console.log('Error', e);
    } finally {
      setLoading(false);
    }
  };

  const getRates = async () => {
    try {
      const res = await api.get('/rates');
      return res.data;
    } catch (e) {
      console.log('Error get rates', e);
    }
  };

  const getLastPayment = async () => {
    const isStatic = getParam('static') === 'true';
    try {
      const res = await api.get('/payment_requests/last');
      if (res?.data?.status === 'approved' && expiredPayment(res.data)) {
        resetOrderPayment();
        return;
      }
      setPayment(res.data);
      return res;
    } catch (e) {
      console.log('Error get last payment', e);
    }
  };

  const getCurrenciesMerchant = async () => {
    setLoading(true);
    try {
      const res = await api.get('/merchants/me');
      if (res.status === 200) {
        setCurrencies(res.data?.currencies);
        return res;
      }
    } catch (e) {
      return;
    } finally {
      setLoading(false);
    }
  };

  const getMerchant = async () => {
    setLoading(true);
    try {
      const res = await api.get('/merchants/me');
      if (res.status === 200) {
        return res;
      }
    } catch (e) {
      return;
    } finally {
      setLoading(false);
    }
  };

  return ({
    checkCoupon,
    getClient,
    getCatalogo,
    getCredentials,
    getRates,
    getLastPayment,
    createPaymentStripe,
    getCurrenciesMerchant,
    getMerchant
  });
};

export default useApi;