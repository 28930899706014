import { Box, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';

const DateField = ({ date }) => {
  const datejs = dayjs(date);
  const theme = useTheme();

  return (
    <Box sx={{ border: '1px solid #000', borderRadius: '6px', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid #000', padding: '0px 7px', backgroundColor: '#74acdf', }}>
        <Typography sx={{ fontSize: '9px', fontWeight: '700', color: '#FFF' }}>{datejs.format('MMM')}</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#FFF' }}>
        <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>{datejs.format('D')}</Typography>
      </Box>
    </Box>
  );
};

export const DoubleDateFromTo = ({ from, until }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
      <DateField date={from} />
      <Typography sx={{ fontWeight: '700', fontSize: '21px' }}>
        -
      </Typography>
      <DateField date={until} />
    </Box>
  );
};

export default DateField;