import { Button, useTheme } from '@mui/material';
import React from 'react';

const ButtonRetro = ({ children, onPress, sx, size = 'default', disabled, ...rest }) => {
  const theme = useTheme();

  const SIZE_BUTTON = {
    default: {
      fontWeight: 'bold',
      padding: '6px 18px',
      fontSize: '14px'
    },
    large: {
      padding: '9px',
      fontSize: '16px',
      fontWeight: 'bold',
    },
  };
  return (
    <Button
      onClick={onPress}
      disabled={disabled}
      sx={{
        ...SIZE_BUTTON[size],
        transition: 'all .4s',
        borderRadius: theme.shape.borderRadius,
        width: '100%',
        ...sx
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

const Contained = ({ children, onPress, disabled, sx, bg = 'rgba(0,0,0,1)', ...rest }) => {
  return (
    <ButtonRetro
      onPress={onPress}
      disabled={disabled}
      sx={{
        backgroundColor: disabled ? 'rgba(0,0,0,.3)' : bg,
        '&:hover': {
          backgroundColor: disabled ? 'rgba(0,0,0,.3)' : bg
        },
        ...sx
      }}
      {...rest}
    >
      {children}
    </ButtonRetro>
  );
};

const Dashed = ({ children, onPress, disabled, sx, ...rest }) => {
  const theme = useTheme();

  return (
    <ButtonRetro
      onPress={onPress}
      disabled={disabled}
      sx={{
        border: disabled ? '1px dashed rgba(0,0,0,.3)' : '1px dashed rgba(0,0,0,.8)',
        color: disabled ? 'rgba(0,0,0,.3)' : 'rgba(0,0,0,.8)',
        borderRadius: theme.shape.borderRadius,
        ...sx
      }}
      {...rest}
    >
      {children}
    </ButtonRetro>
  );
};

const Outlined = ({ children, onPress, disabled, sx, size, ...rest }) => {
  const theme = useTheme();
  return (
    <ButtonRetro
      onPress={onPress}
      disabled={disabled}
      size={size}
      sx={{
        border: disabled ? '1px solid rgba(0,0,0,.7)' : '1px solid #000',
        color: '#000',
        boxShadow: theme.shadows.light,
        backgroundColor: '#FFF',
        '&:focus': {
          backgroundColor: '#FFF'
        },
        // '&:hover': {
        //   backgroundColor: 'rgba(210,210,210,1)'
        // },
        // '&:active': {
        //   backgroundColor: 'rgba(210,210,210,1)'
        // },
        ...sx
      }}
      {...rest}
    >
      {children}
    </ButtonRetro>
  );
};

const Text = ({ children, onPress, disabled, ...rest }) => {
  return (
    <ButtonRetro
      onPress={onPress}
      disabled={disabled}
      style={{
        color: disabled ? 'rgba(0,0,0,.3)' : 'rgba(0,0,0,1)'
      }}
      {...rest}
    >
      {children}
    </ButtonRetro>
  );
};

ButtonRetro.Contained = Contained;
ButtonRetro.Text = Text;
ButtonRetro.Outlined = Outlined;
ButtonRetro.Dashed = Dashed;

export default ButtonRetro;