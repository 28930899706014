import { defineChain } from 'thirdweb';

const isTest = process.env.REACT_APP_DEVELOPMENT === 'true';

export const ETHEREUM = defineChain({
  id: 1,
  name: 'Ethereum Mainnet',
  rpc: 'https://go.getblock.io/ac0ac6581b2044f3beaa9f08d3b67439',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18
  },
});

export const OPTIMISM = defineChain({
  id: 10,
  name: 'OP Mainnet',
  rpc: 'https://go.getblock.io/eb1029c611e54e459b1feecea77d8aa5',
  nativeCurrency: {
    name: 'ETH', 
    symbol: 'ETH', 
    decimals: 18
  },
});

export const LACHAIN = defineChain({
  id: 274,
  name: 'LaChain',
  rpc: 'https://rpc1.mainnet.lachain.network',
  nativeCurrency: {
    decimals: 18,
    name: 'LaCoin',
    symbol: 'LAC',
  },
});

export const POLYGON = defineChain({
  id: 137,
  name: 'Polygon Mainnet',
  rpc: 'https://go.getblock.io/d899bf2097b94aa8ae491057a512cc95',
  shortName: 'Polygon',
  slug: 'polygon',
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 6
  },
});

export const ROOSTOCK = defineChain({
  id: 30,
  name: 'rsk',
  rpc: 'https://mycrypto.rsk.co/',
  nativeCurrency: {
    decimals: 18,
    name: 'RSK',
    symbol: 'rbtc',
  },
  shortName: 'RSK',
  slug: 'rsk',
});

const SEPOLIA = defineChain({
  id: 11155111,
  name: 'Sepolia test network',
  shortName: 'Sepolia',
  slug: 'sepolia',
  nativeCurrency: {
    name: 'SepoliaETH',
    symbol: 'SepoliaETH',
    decimals: 6
  },
  rpc: 'https://rpc2.sepolia.org',
  testnet: true
});

const BASE = defineChain({
  id: 8453,
  name: 'Base Mainnet',
  shortName: 'Base',
  slug: 'base',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18
  },
  rpc: 'https://go.getblock.io/1e9f731923334f308f6540da5f1248c5',
});

export const ZKSYNC = defineChain({
  id: 324,
  name: 'ZKsync Era Mainnet',
  shortName: 'ZKsync Era',
  slug: 'zksync',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18
  },
  rpc: 'https://go.getblock.io/8afdbc5f922b47b7ba0611e1678c2542'
});

export const STRIPE = {
  id: 15,
  ticker: 'Credit Card',
  subticker: '(3% processing charges)',
  chain_name: 'STRIPE',
  isEVM: false,
  currency_logo: require('./Images/logo_card.png'),
  chain_logo: require('./Images/logo_stripe.png'),
  blockchain: 'Stripe'
};


export const SUPPORTED_CHAIN = [LACHAIN, POLYGON, ROOSTOCK, ETHEREUM, OPTIMISM, BASE, ZKSYNC, isTest && SEPOLIA]; 

export const ACTIVE_CHAIN_DEFAULT = POLYGON;