import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useContext } from 'react';
import dayjs from 'dayjs';
import { FormContext } from '../../../providers/FormProvider';
import { TICKETS } from '../../Crecimiento/Constants';
import { PlanInputs } from './PlanInputs';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const isPatron = (arr, item) => {
  console.log(item);
  if(item.type === 'month') {
    if(arr.find(it => it.price === item.price)) {
      return arr.filter(it => it.id !== item.id);
    } else {
      return arr.map(it => {
        let obj = it;
        obj.price = item.price;
        return obj;
      });
    }
  }
  return arr.filter(it => it.id !== item.id);
};

const PlanComponent = ({ initialValue, ticket = 'Standard', screen,handler, ...rest}) => {

  const { form: { reference } } = useContext(FormContext);
  const [value, setValue] = useState(initialValue ?? []);
  const ticketSelection = TICKETS['all']?.[ticket]?.week;
    
  const handlePressItem = (item) => {
    let filteredMonth = value?.filter(item => item.type !== 'month');
    if(value?.find(it => it.id === item.id)) return setValue(prevState => isPatron(prevState, item));
    if(item.type === 'month') return setValue([item]);
    if(value.length === 3 && !value.find((it) => it.id === item.id)) {
      let monthValue = ticketSelection?.find(item => item.type === 'month');
      return setValue([monthValue]);
    } 
    return setValue([...filteredMonth, item]);
  };

  useEffect(() => handler && handler(value),[value]);

  if (reference) {
    return (
      <PlanInputs {...rest} order={value} screen={screen} onChange={handlePressItem} choices={ticketSelection} />
    );
  }
};

export default PlanComponent;

