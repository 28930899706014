import { Box, Typography } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { FormContext } from '../../providers/FormProvider';
import { calculateTotalBeforePayment } from '../../providers/StepProvider';

const TotalAmount = () => {
  const { form: { total, discount, reference, ars_amount, priceDiscount } } = useContext(FormContext);

  // console.log('total', total, reference, ars_amount);
  const totalPayment = useMemo(() => {
    return calculateTotalBeforePayment(reference.attendees, discount);
  },[discount, reference]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
      {
        (discount && discount > 0) ? (
          <Typography sx={{ fontSize: '14px', fontWeight: '600', color: 'rgba(0,0,0,.7)', textDecoration: 'line-through' }}>$ {calculateTotalBeforePayment(reference.attendees)}</Typography>
        ) : null
      }
      <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>$ {totalPayment.toFixed(2)}</Typography>
    </Box>
  );
};

export default TotalAmount;