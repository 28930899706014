import React from 'react';
import { useTheme } from '@mui/material';
import './index.css';
import { HelperTextError } from '../Typographies';


const Input = ({ errorMsg, label, icon = null, endIcon = null, size = 'default', style, ...rest }) => {
  const theme = useTheme();

  const input_size = {
    default: {
      boxShadow: theme.shadows.light,
      fontSize: '24px',
      fontWeight: 600,
      color: '#333333',
      padding: '12px 15px'
    },
    small: {
      boxShadow: theme.shadows.minimal,
      fontSize: '16px',
      fontWeight: 600,
      color: '#333333',
      padding: '12px 15px',
    },
    minimal: {
      boxShadow: theme.shadows.minimal,
      fontSize: '14px',
      fontWeight: 600,
      color: '#333333',
      padding: '12px 15px'
    }
  };

  return (
    <div id="box-input">
      <div 
      
        className={`inputCustom ${icon ? 'paddingIcon' : ''}`}
     
      >
        {
          label && <label htmlFor="inputCustom">{label}</label>
        }
        {
          icon && (
            <div className='inputIcon'>
              {icon}
            </div>
          )
        }
        <input
          id="inputCustom"
          name="inputCustom"
          style={{
            borderRadius: theme.shape.borderRadius * 3,
            // border: errorMsg ? '2px solid red' : null,
            ...input_size[size],
            ...style
          }}
          {...rest}
        />
        {
          endIcon && (
            <div className='inputEndIcon'>
              {endIcon}
            </div>
          )
        }
    
      </div>
      {
        errorMsg && (
          <HelperTextError sx={{
            marginLeft: '10px',
            color: '#fff'
            // color: 'rgb(255, 0, 0)'
          }}>
            {errorMsg}
          </HelperTextError>
        )
      }
    </div>

  );
};

export default Input;