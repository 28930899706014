import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

//types = light, normal, dense

const Card = ({ children, direction = 'column', selected, onPress, type = 'minimal', style, disabled }) => {
  const theme = useTheme();

  const TYPES = {
    minimal: {
      borderWidth: '2px 2px 6px 6px',
      borderColor: '#000',
      borderStyle: 'solid',
      borderRadius: `${theme.shape.borderRadius * 6}px`,
    }
  };

  return (
    <Box
      onClick={onPress}
      sx={{
        display: 'flex',
        flexDirection: direction,
        alignItems: 'center',
        backgroundColor: selected ? '#ffda7a' : disabled ? '#c2c2c2' : '#fff',
        objectPosition: 'center bottom',
        backgroundSize: 'cover',
        padding: 1,
        margin: '6px 0',
        cursor: onPress && 'pointer',
        borderRadius: '12px',
        // ...theme.border.minimal,
        ...style
      }}
    >
      {children}
    </Box>
  );
};

const Image = ({ src, style }) => {
  return (
    <img
      src={src}
      style={{ ...style, marginRight: 12 }}
    />
  );
};

const Title = ({ children, color, ...rest }) => {
  return (
    <Typography variant='subtitle1' color={color} {...rest}>{children}</Typography>
  );
};

const Chip = ({ logo, title, selected }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        padding: '3px 9px',
        borderRadius: '8px',
        backgroundColor: 'rgba(0,0,0,.2)',
        filter: 'opacity(.85)',
        // border: '1px solid #000',
        alignItems: 'center',
        gap: 1,
        transition: 'all .5s'
      }}
    >
      {
        logo && <img src={logo} style={{ width: 14, height: 14 }} />
      }
      <Typography variant='subtitle1' fontSize={'12px'} fontWeight={'500'} color={'#000'}>{title}</Typography>
    </Box>
  );
};

const Outlined = ({ children, direction = 'column', selected, type = 'minimal', style, backgroundColor = 'transparent', onClick }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: direction,
        alignItems: 'center',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: selected ? 'rgba(0,0,0,.14)' : backgroundColor,
        // backgroundImage: selected ? `url(${background})` : null,
        filter: 'grayscale(0)',
        objectPosition: 'center bottom',
        backgroundSize: 'cover',
        border: `1px solid ${selected ? 'rgba(0, 0, 0, .8)' : 'rgba(0, 0, 0, .7)'}`,
        padding: 1,
        margin: '6px 0',
        // boxShadow: theme.shadows[type],
        ...style
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

Card.Image = Image;
Card.Title = Title;
Card.Chip = Chip;
Card.Outlined = Outlined;


export default Card;