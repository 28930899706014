import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, Collapse } from '@mui/material';
import { PaymentContext } from '../../providers/PaymentProvider';
import usePayment from '../../hooks/usePayment';
import { STATUS_PAYMENT } from '../../assets/Constants';
import { translate } from '../../translate';
import ButtonRetro from '../../components/ButtonRetro/ButtonRetro';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Input from '../../components/Input/Input';
import useApi from '../../hooks/useApi';
import Loader from '../../components/Loader/Loader';
import { removeItemStorage } from '../../helpers/storage';

const Response = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const isStatic = urlParams.get('static') === 'true';
  const { selectedToken, status } = useContext(PaymentContext);
  const { resetOrderPayment } = usePayment();

  if ((status === STATUS_PAYMENT.expired || status === STATUS_PAYMENT.canceled) && !isStatic) {
    resetOrderPayment();
    return;
  }

  return (
    <Box sx={{ height: '100%' }}>
      {
        (status === STATUS_PAYMENT.expired || status === STATUS_PAYMENT.canceled) && (
          <Expired handleResetPayment={resetOrderPayment} />
        )
      }

      {/* {
        status === STATUS_PAYMENT.canceled && (
          <Canceled handleResetPayment={resetOrderPayment} />
        )
      } */}

      {
        status === STATUS_PAYMENT.not_found && (
          <NotFound />
        )
      }
      {
        status === STATUS_PAYMENT.repaid && (
          <Repaid />
        )
      }

      {
        status === STATUS_PAYMENT.error && (
          <Error />
        )
      }

      {
        status === STATUS_PAYMENT.in_process && (
          <Loading selectedToken={selectedToken.token} />
        )
      }
    </Box>
  );
};

const NotFound = () => {
  return (
    <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', gap: 1, alignItems: 'center', flexDirection: 'column' }}>
      {/* <Typography sx={{ fontSize: '16px', }}>Oh no :( </Typography> */}
      <Typography sx={{ fontSize: '24px', fontWeight: '600' }}>404</Typography>
      <Typography sx={{ fontSize: '16px', }}>NOT FOUND</Typography>
      {/* <Typography sx={{ fontSize: '14px', }}>:(</Typography> */}

    </Box>
  );
};

const Canceled = () => {
  const [disabled, setDisabled] = useState(false);
  const { getLastPayment } = useApi();

  const handleRefetchLast = async () => {
    setDisabled(true);
    await getLastPayment();

    setTimeout(() => {
      console.log('entre');
      setDisabled(false);
    }, 1500);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', gap: 2 }}>
      <Typography variant='body1' textAlign={'center'} fontSize={'21px'} fontWeight={'bold'} color={'#FFF'}>
        No se creó la orden.
      </Typography>
      <Typography variant='body1' textAlign={'center'} fontSize={'18px'} fontWeight={'bold'} color={'#FFF'}>
        Avisale al cajero que estas listo para pagar.
      </Typography>
      <div>
        <ButtonRetro.Outlined onPress={handleRefetchLast} disabled={disabled}>Ya le avisé</ButtonRetro.Outlined>
      </div>
    </Box>
  );
};

const Expired = () => {
  const [disabled, setDisabled] = useState(false);
  const { getLastPayment } = useApi();

  const handleRefetchLast = async () => {
    setDisabled(true);
    await getLastPayment();

    setTimeout(() => {
      console.log('entre');
      setDisabled(false);
    }, 1500);
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', gap: 2 }}>
      <Typography variant='body1' textAlign={'center'} fontSize={'21px'} fontWeight={'bold'} color={'#FFF'}>
        No se creó la orden.
      </Typography>
      <Typography variant='body1' textAlign={'center'} fontSize={'18px'} fontWeight={'bold'} color={'#FFF'}>
        Avisale al cajero que estas listo para pagar.
      </Typography>
      <div>
        <ButtonRetro.Outlined onPress={handleRefetchLast} disabled={disabled}>Ya le avisé</ButtonRetro.Outlined>
      </div>
    </Box>
  );
};


const Repaid = () => {
  return (
    <Box>
      <Typography variant='body1' textAlign={'center'} fontSize={'18px'} fontWeight={'bold'}>
        Esta transacción ya fue procesada anteriormente.
      </Typography>
    </Box>
  );
};

const Error = () => {
  const { setStatus } = useContext(PaymentContext);

  const handleClick = () => {
    removeItemStorage('attendee_id');
    setStatus(STATUS_PAYMENT.pending);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: 'center', flexDirection: 'column', height: '100%' }}>
      <Typography sx={{ fontSize: '21px', fontWeight: '600' }}>Attendee not found</Typography>
      <ButtonRetro.Contained onPress={handleClick}>Retry</ButtonRetro.Contained>
    </Box>
  );
};

const Loading = ({ selectedToken }) => {
  const { refetchPaymentRequest } = usePayment();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    refetchPaymentRequest();
  }, []);

  const TOKEN_TIMES = {
    USDT: 15,
    DOC: 30,
    UXD: 10
  };

  const handleOpenDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, justifyContent: 'center', flexDirection: 'column', height: '100%', position: 'relative' }}>
      <Box sx={{ flex: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 5 }}>
        <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>{translate.paymentInProcess}</Typography>
        {
          selectedToken && TOKEN_TIMES[selectedToken?.coin] && (
            <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>{translate.timeEstimated} {TOKEN_TIMES[selectedToken?.coin]} {translate.seconds}</Typography>
          )
        }
        <Loader full={false} />
      </Box>

      <Box sx={styles.containerHelper}>
        <Box sx={{ width: '100%', textAlign: 'center', marginBottom: '9px' }} onClick={handleOpenDrawer}>
          <Typography sx={{ textAlign: 'center', fontWeight: '600', fontSize: '16px' }}>do you have any problem?</Typography>
        </Box>
        <Collapse in={open}>
          <HelpSendTransaction />
          <HelpOpenWallet />
        </Collapse>
      </Box>
    </Box>
  );
};

const HelpOpenWallet = () => {
  const { transfer } = usePayment();
  const [disabled, setDisabled] = useState(false);

  const handlePressTransfer = (e) => {
    setDisabled(true);
    transfer();
    setTimeout(() => { setDisabled(false); }, 5000);
  };

  return (
    <HelperTextCollapse title={'MetaMask did not open the transaction signing modal.'}>
      <Box sx={{ display: 'flex', justifyContent: 'center', margin: '9px' }}>
        <ButtonRetro.Contained disabled={disabled} onPress={handlePressTransfer}>Retry</ButtonRetro.Contained>
      </Box>
    </HelperTextCollapse>
  );
};

const HelpSendTransaction = () => {
  const [hash, setHash] = useState('');
  const { linkHashPayment } = usePayment();

  const handleSendHash = () => {
    linkHashPayment(hash);
  };

  const handleChange = ({ target }) => {
    setHash(target.value);
  };

  return (
    <HelperTextCollapse title={'I made the transaction but I don\'t see my payment.'}>
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: '3px 9px' }}>
        <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>Share the transaction hash with us.</Typography>
        <Input
          size={'minimal'}
          onChange={handleChange}
          placeholder={translate.transactionHash}
          style={{ margin: '6px 0 0 0 ', boxShadow: 'none' }}
          onClick={(event) => event.stopPropagation()}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: '9px 15px 0 0' }}>
          <div>
            <ButtonRetro.Text size={'small'} onClick={handleSendHash} disabled={hash === ''}>{translate.send}</ButtonRetro.Text>
          </div>
        </Box>
      </Box>
    </HelperTextCollapse>
  );
};

const HelperTextCollapse = ({ children, title }) => {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '21px', borderBottom: '1px solid rgba(0,0,0,.4)' }} >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', paddingBottom: '6px' }} onClick={() => setOpen(!open)}>
        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>{title}</Typography>
        {
          open ? <ExpandLessRoundedIcon sx={{ fontSize: '21px' }} /> : <ExpandMoreRoundedIcon sx={{ fontSize: '21px' }} />
        }
      </Box>
      <Collapse in={open}>
        {children}
      </Collapse>
    </Box>
  );
};

const styles = {
  containerHelper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'transparent',
    paddingTop: '12px',
    borderTopLeftRadius: '9px',
    borderTopRightRadius: '9px',
  }
};
export default Response;