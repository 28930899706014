import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useContext } from 'react';
import dayjs from 'dayjs';
import { TICKETS, TICKETS_TYPES } from '../Crecimiento/Constants';
import { ClientContext } from '../../providers/ClientProvider';
import { FormContext } from '../../providers/FormProvider';
import Loader from '../../components/Loader/Loader';
import { InputCoupon } from '../../components/CouponInput/CouponInput';
import { PlanInputs } from './components/PlanInputs';
import { StepContext } from '../../providers/StepProvider';
import PlanComponent from './components/PlanComponent';
import { parseWeeks } from '../../helpers/parseWeeks';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const findTicketHolder = (values) => {
  let found = values?.find(item => ['patron', 'scholar', 'builder', 'standard'].includes(item.category));
  if(found) return found;
  return null;
};
const ClientInfo = () => {
  const { client, alreadyPaid, loadingClient } = useContext(ClientContext);
  const { form, setForm } = useContext(FormContext);
  const [order, setOrder] = useState(findTicketHolder(form.reference.attendees));
  const { disabled, setDisabled, step } = useContext(StepContext);

  const ticketOrigin = 'all';

  const ticketSelection = useMemo(() => {
    if (client) return TICKETS[ticketOrigin]?.[client?.['what_type_of_ticket']];
  }, [client]);

  const findClient = (arr, client) => {
    let newArr = arr;
    var foundIndex = newArr.findIndex(x => x.category === client.category);
    if (foundIndex !== -1) {
      newArr[foundIndex] = client;
    } else {
      newArr = [...newArr, client];
    }

    return newArr;
  };

  useEffect(() => {

    if (order) {
      let price = order?.weeks?.reduce((a, b) => a + b.price, 0);
      let obj = {
        ...order,
        price: price
      };

      setForm(prev => ({
        ...prev,
        reference: {
          ...prev.reference,
          attendees: findClient(prev.reference.attendees, obj)
        }
      }));
      setDisabled({ prev: false, next: false });
    }
    if (!order?.weeks || order.weeks?.length === 0) setDisabled({ prev: false, next: true });
  }, [order]);

  const handler = (value) => {

    setOrder((prevState) => ({ ...prevState, weeks: value }));
    
  };

  useEffect(() => {
    if (client) {
      
      setOrder(prev => ({
        ...prev,
        name: client.first_name,
        email: client.email,
        category: client?.['what_type_of_ticket'],
      }));
    }
  }, [client]);

  if (loadingClient) {
    return (
      <Loader />
    );
  }

  return (
    <Fragment key="plan-component">
      {
        client ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', height: '100%', marginTop: '10px' }}>
            {
              ticketSelection?.subtitle && (
                ticketSelection?.subtitle(`${client?.first_name + ' ' + client?.last_name}`, client?.['what_type_of_ticket'])
              )
            }
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', marginTop: '12px' }}>
              <PlanComponent initialValue={findTicketHolder(form.reference.attendees)?.weeks} handler={handler} ticket={client?.['what_type_of_ticket']} screen="plan" />
            </Box>
          </Box>
        ) : !client && alreadyPaid ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            A purchase has been made for this application.
          </Box>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <Typography>
          This is not a valid link. Please make sure to apply <a href="https://airtable.com/appci7xgbOZKP1u37/pagSzSTdJZ59n7kIh/form" style={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', fontWeight: 600, borderBottom: '1px solid blue' }}>here</a>
            </Typography>
          </Box>
        )
      }
    </Fragment>
  );

};

export default ClientInfo;